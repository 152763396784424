import { observable, action } from 'mobx';
import * as api from '../services/api';
import moment from 'moment';

class AttendanceStore {
    @observable scannerLoading = false;
    @observable scannedQrCode = null;
    @observable scannedQrCodeError = null;

    @action handledScannedAttendance = (code) => {
        this.scannerLoading = true;
        api.handledScannedAttendance(code)
            .then((res) => {
                this.scannedQrCode = res;
                this.scannedQrCodeError = null;
                setTimeout(() => {
                    this.scannedQrCode = null;
                }, 3000);
            })
            .catch((err) => {
                this.scannedQrCode = null;
                this.scannedQrCodeError = err.message;
                setTimeout(() => {
                    this.scannedQrCodeError = null;
                }, 3000);
            })
            .finally(() => {
                this.scannerLoading = false;
            });
    };
}

const store = new AttendanceStore();
export default store;
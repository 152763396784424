import { action, observable } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { getFromLocalStorage, saveToLocalStorage } from '../services/utils';
import moment from 'moment';
import { isEqual } from 'lodash';

class PickModeStore {
    ALL_OVEN_TYPES = 'ALL_OVEN_TYPES';

    STARTED = 'Started';
    PICKED = 'Picked';

    @observable loading = true;
    @observable pendingDineInOrders = null;
    @observable date = moment().format('DD-MM-YYYY');
    @observable productionList = [];
    @observable currentStartProductionCycles = null;
    @observable allItemsInAllMeals = [];
    @observable ovenType =
        getFromLocalStorage('ovenType') || this.ALL_OVEN_TYPES;
    @observable pendingOrders = [];
    @observable waveItemsCountOriginal = null;
    @observable filteredWavesIndices = [];
    @observable pendingDeliveryOrders = [];

    dineInOrdersLastModified = null;
    productionWavesLastModified = null;
    deliveryOrdersLastModified = null;
    @action setDate = (date) => {
        this.loading = true;
        this.date = date;
        this.handleGetDineInOrders(true);
    };

    @action resetProductionLastModified = () => {
        this.productionWavesLastModified = null;
    };

    removeDineInItemsFromLocalStorage = () => {
        //this function will remove the items from local storage that aren't in Starting status.
        const dineInItems = this.loadItemsFromLocalStorage(true);
        const pendingOrdersOrderNum = this.pendingOrders.map(
            (order) => order.order_number
        );
        const newDineInItems = dineInItems.filter((item) => {
            return pendingOrdersOrderNum.includes(item.order_number);
        });
        saveToLocalStorage('pick-mode-dineIn-items', newDineInItems);
    };

    removeDeliveryItemsFromLocalStorage = () => {
        //this function will remove the items from local storage that aren't in Starting status.
        const deliveryItems = this.loadItemsFromLocalStorage(false);
        const pendingOrdersOrderNum = this.pendingDeliveryOrders.map(
            (order) => order.order_number
        );
        const newDeliveryItems = deliveryItems.filter((item) => {
            return pendingOrdersOrderNum.includes(item.order_number);
        });
        saveToLocalStorage('pick-mode-picked-items', newDeliveryItems);
    };

    loadItemsFromLocalStorage = (dineInItems = false) => {
        //pickedItems have singular items stored.
        let items;
        if (dineInItems) {
            items = getFromLocalStorage('pick-mode-dineIn-items') || [];
        } else {
            items = getFromLocalStorage('pick-mode-picked-items') || [];
        }

        return items;
    };

    @action changeStatusOfItem = (item, isOnDemandProduction) => {
        if (item.isPicked) {
            //item is picked. have to undo pick
            if (item.order_date) {
                //this is dine-in order
                const dineInItems = this.loadItemsFromLocalStorage(true);
                let itemFound = false;
                const newDineInItems = dineInItems.filter((dineInItem) => {
                    if (itemFound) {
                        return true;
                    }
                    if (
                        item.order_number === dineInItem.order_number &&
                        item.sku === dineInItem.sku
                    ) {
                        itemFound = true;
                        return false;
                    } else {
                        return true;
                    }
                });
                saveToLocalStorage('pick-mode-dineIn-items', newDineInItems);
                this.handleDineInOrdersData();
            } else if (isOnDemandProduction) {
                //this is on-demand delivery
                const deliveryItems = this.loadItemsFromLocalStorage(false);
                let itemFound = false;
                const newDeliveryItems = deliveryItems.filter(
                    (deliveryItem) => {
                        if (itemFound) {
                            return true;
                        }
                        if (
                            item.order_number === deliveryItem.order_number &&
                            item.sku === deliveryItem.sku
                        ) {
                            itemFound = true;
                            return false;
                        } else {
                            return true;
                        }
                    }
                );
                saveToLocalStorage('pick-mode-picked-items', newDeliveryItems);
                this.handleDeliveryOrdersData();
            } else {
                //this is pickup/delivery order
                const pickedItems = this.loadItemsFromLocalStorage();
                let itemFound = false;
                const newPickedItems = pickedItems.filter((pickedItem) => {
                    if (itemFound) {
                        return true;
                    }
                    const waveIdSku = pickedItem.id.split('_wave_')[1];
                    const [waveID, sku] = waveIdSku.split('_sku_');
                    if (
                        item.wave_id === waveID &&
                        item.sku === sku &&
                        item.index === pickedItem.index
                    ) {
                        itemFound = true;
                        return false;
                    } else {
                        return true;
                    }
                });
                saveToLocalStorage('pick-mode-picked-items', newPickedItems);
                this.extractAllItemsInAllMeals();
            }
        } else {
            //item is not picked yet. have to pick it.
            if (item.order_date) {
                //this is dine-in order
                const dineInItems = this.loadItemsFromLocalStorage(true);
                saveToLocalStorage('pick-mode-dineIn-items', [
                    ...dineInItems,
                    item,
                ]);
                this.handleDineInOrdersData();
            } else if (isOnDemandProduction) {
                //this is on-demand delivery order
                const deliveryItems = this.loadItemsFromLocalStorage(false);
                saveToLocalStorage('pick-mode-picked-items', [
                    ...deliveryItems,
                    item,
                ]);
                this.handleDeliveryOrdersData();
            } else {
                //this is pickup/delievery order
                const pickedItems = this.loadItemsFromLocalStorage();
                saveToLocalStorage('pick-mode-picked-items', [
                    ...pickedItems,
                    item,
                ]);
                this.extractAllItemsInAllMeals();
            }
        }
    };

    handlePickModeScreenData = (responseBody) => {
        //this function handles all of data processing and handling needed for pickup mode screen from Production store
        const validStartedWaves = (wave) => wave.status === this.STARTED;
        const startWaves = responseBody.waves
            .filter(validStartedWaves)
            .reverse();
        const startItems = responseBody.items.map((item) => {
            return {
                ...item,
                waves: item.waves.filter(validStartedWaves).reverse(),
            };
        });

        this.currentStartProductionCycles = {
            waves: startWaves,
            items: startItems,
        };

        this.extractAllItemsInAllMeals();
    };

    handleDineInOrdersData = () => {
        const pickedDineInItems = this.loadItemsFromLocalStorage(true);
        const pickedDineInItemsString = pickedDineInItems.map(
            (item) =>
                `${item.order_number}_${item.sku}_${item.incremental_number}`
        );

        const modifiedPendingDineInOrders = this.pendingOrders.map((order) => {
            let areAllItemsPicked = [];
            const modifiedItems = order.items.map((item) => {
                let isItemPicked;
                //temRepString: string that represents item uniquely
                const itemRepString = `${item.order_number}_${item.sku}_${item.incremental_number}`;
                if (pickedDineInItemsString.includes(itemRepString)) {
                    isItemPicked = true;
                    areAllItemsPicked.push(true);
                } else {
                    isItemPicked = false;
                    areAllItemsPicked.push(false);
                }
                return {
                    ...item,
                    order_number: order.order_number,
                    isPicked: isItemPicked,
                };
            });
            const modifiedOrder = { ...order, items: modifiedItems };
            if (
                areAllItemsPicked.length > 0 &&
                areAllItemsPicked.every(Boolean)
            ) {
                //all items of this order are picked so don't show it
                return null;
            }
            return modifiedOrder;
        });

        const filteredModifiedOrders = modifiedPendingDineInOrders.filter(
            (order) => order
        );
        this.pendingDineInOrders =
            filteredModifiedOrders.sort(
                (a, b) => a.order_date - b.order_date
            ) || [];
    };

    handleDeliveryOrdersData = () => {
        const pickedDeliveryItems = this.loadItemsFromLocalStorage(false);
        const pickedDeliveryItemsString = pickedDeliveryItems.map(
            (item) =>
                `${item.order_number}_${item.sku}_${item.incremental_number}`
        );

        const modifiedPendingDeliveryOrders = this.pendingDeliveryOrders.map(
            (order) => {
                let areAllItemsPicked = [];
                const modifiedItems = order.items.map((item) => {
                    let isItemPicked;
                    const itemRepString = `${item.order_number}_${item.sku}_${item.incremental_number}`;
                    if (pickedDeliveryItemsString.includes(itemRepString)) {
                        isItemPicked = true;
                        areAllItemsPicked.push(true);
                    } else {
                        isItemPicked = false;
                        areAllItemsPicked.push(false);
                    }
                    return {
                        ...item,
                        order_number: order.order_number,
                        isPicked: isItemPicked,
                    };
                });
                const modifiedOrder = { ...order, items: modifiedItems };
                if (
                    areAllItemsPicked.length > 0 &&
                    areAllItemsPicked.every(Boolean)
                ) {
                    //all items of this order are picked so don't show it
                    return null;
                }
                return modifiedOrder;
            }
        );

        const filteredModifiedOrders = modifiedPendingDeliveryOrders.filter(
            (order) => order
        );
        this.pendingDeliveryOrders =
            filteredModifiedOrders.sort(
                (a, b) => a.order_date - b.order_date
            ) || [];
    };

    @action handleGetDineInOrders = (forceLatest = false, date = this.date) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.dineInOrdersLastModified;
        }
        api.getDineInOrders(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.dineInOrdersLastModified = lastModified;
                    if (!responseBody.list) {
                        //there's no dine-in order
                        this.pendingOrders = [];
                    }
                    //this array will be used to filter out completed orders from prepared/Done orders list
                    if (responseBody.list && responseBody.list.length > 0) {
                        this.pendingOrders =
                            responseBody.list.filter(
                                (order) => order.status === 'Pending'
                            ) || [];
                    }
                    this.removeDineInItemsFromLocalStorage();
                    this.handleDineInOrdersData();
                } else {
                    // no change since the last call
                }
                this.loading = false;
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            });
    };

    @action handleGetDeliveryOrders = (
        forceLatest = false,
        date = this.date
    ) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.deliveryOrdersLastModified;
        }
        api.getDeliveryOrders(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.deliveryOrdersLastModified = lastModified;
                    if (!responseBody.list) {
                        //there's no delivery order
                        this.pendingDeliveryOrders = [];
                    }
                    //this array will be used to filter out completed orders from prepared/Done orders list
                    if (responseBody.list && responseBody.list.length > 0) {
                        this.pendingDeliveryOrders =
                            responseBody.list.filter(
                                (order) => order.status === 'Pending'
                            ) || [];
                    }
                    this.removeDeliveryItemsFromLocalStorage();
                    this.handleDeliveryOrdersData();
                } else {
                    // no change since the last call
                }
                this.loading = false;
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            });
    };
}

const store = new PickModeStore();
export default store;

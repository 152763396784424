import React, { Component } from 'react';
import { observer } from 'mobx-react';

const base = 'cooking-v2';

@observer
class InOutletSubHeadingWrapper extends Component {
    render() {
        const { haveInOutletOrders } = this.props
        return(
            <div className={`${base}__sub_heading_wrapper`}>
                <div className={`${base}__sub_heading display--flex`}>
                    <img src='https://image.popcontent.link/dish-fork-and-knife+1.svg' style={{width: '3rem', height: '3rem'}}/>
                    <p className={`${base}__dine_in_heading`}>Dine-in / Take Away</p>
                    <img src='https://image.popcontent.link/takeaway_icon.svg' style={{width: '2rem', height: '2.5rem'}}/>
                </div>
                {haveInOutletOrders && (
                    <div className={`${base}__priority_tag`}> PRIORITY </div>
                )}

            </div>
        )
    }
}
export default InOutletSubHeadingWrapper;

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import SwitchButton from 'lyef-switch-button';
import moment from 'moment';

const base = 'secondary-header';

@inject('UserStore', 'DispatchStore')
@observer
class DispatchScanHeader extends Component {
    state = {
        currentTime: null,
    };

    componentDidMount = () => {
        this.currentTime();
    };

    handleToggleSwitch = () => {
        const { DispatchStore } = this.props;
        const { setAutoPrint, isAutoPrint } = DispatchStore;

        setAutoPrint(!isAutoPrint);
    };

    currentTime = () => {
        const currentTime = moment().format('LTS');
        this.setState({ currentTime });
        setTimeout(this.currentTime, 1000);
    };

    render() {
        const { currentTime } = this.state;
        const { DispatchStore, goFullscreen, UserStore } = this.props;
        const { date, isAutoPrint, handleGetDispatches } = DispatchStore;
        const { isPrinterConnected } = UserStore;

        return (
            <div className={`${base}__wrapper bg--white`}>
                <div className={`${base} display--flex  pll prl ptm pbm`}>
                    <div
                        className={`${base}__left display--flex justify--space-between flex-align-items-center`}
                    >
                        <DateInput
                            formatDate={(date) =>
                                moment(date).date() === moment().date()
                                    ? moment(date).format(
                                          '[Today •] DD/MM/YYYY'
                                      )
                                    : moment(date).format('DD/MM/YYYY')
                            }
                            onChange={(date) => handleGetDispatches(date, true)}
                            parseDate={(str) =>
                                moment(str, 'DD/MM/YYYY').toDate()
                            }
                            placeholder="Pick a date"
                            value={moment(date, 'DD/MM/YYYY').toDate()}
                            inputProps={{ leftIcon: 'calendar' }}
                        />
                        <p className="fs--medium-medium-large m0 mlm">
                            {currentTime}
                        </p>
                    </div>
                    <div
                        className={`${base}__right display--flex justify--space-between flex-align-items-center`}
                    >
                        <svg fill="#fff" className="iconmoon-icon icon-print">
                            <use xlinkHref={`#icon-print`} />
                        </svg>
                        <p className="fs--medium-medium-large ms">{`Auto Print`}</p>
                        <SwitchButton
                            id={`switch`}
                            isChecked={isAutoPrint}
                            action={this.handleToggleSwitch}
                            disabled={!isPrinterConnected}
                        />
                        <button
                            className={`btn btn-borderless mls`}
                            onClick={() => goFullscreen()}
                        >
                            <Icon icon="fullscreen" iconSize={24} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DispatchScanHeader;

import React from 'react';

export const BrandText = ({ className = '' }) => {
    return (
        <div className="display--inline-block">
            <svg
                height="100%"
                className={`logo__text ${className}`}
                version="1.1"
                viewBox="0 0 759.571 131.958"
                x="0px"
                y="0px"
                xmlSpace="preserve"
            >
                <g>
                    <path
                        d="M65.938,2.117v63.234c-4.92-8.408-15.235-12.695-26.188-12.695c-20.318,0-37.306,14.922-37.306,38.575&#xA;&#x9;&#x9;c0,23.486,17.145,38.727,37.463,38.727c10.319,0,21.111-4.6,26.031-13.014v1.137c0,5.682,4.606,10.289,10.288,10.289h4.158V2.117&#xA;&#x9;&#x9;H65.938z M41.176,115.831c-13.333,0-23.967-10.158-23.967-24.762c0-14.76,10.634-24.285,23.967-24.285&#xA;&#x9;&#x9;c11.905,0,24.605,8.412,24.605,24.285C65.782,106.786,54.19,115.831,41.176,115.831"
                    />
                    <path
                        d="M163.254,116.944c-4.92,8.414-15.712,13.014-26.032,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.32,0,21.112,4.287,26.032,12.695V54.245h14.284v74.125h-3.996&#xA;&#x9;&#x9;c-5.682,0-10.288-4.607-10.288-10.289V116.944z M138.493,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.604-9.045,24.604-24.762C163.097,75.196,150.398,66.784,138.493,66.784"
                    />
                    <path
                        d="M201.382,128.37h-4.152V2.113h14.441v63.239c4.443-8.889,16.349-12.695,24.446-12.695&#xA;&#x9;&#x9;c18.567,0,30.158,12.062,30.001,32.857v42.855h-14.445V86.465c0-12.221-7.305-19.682-17.464-19.682&#xA;&#x9;&#x9;c-10.157,0-22.538,5.711-22.538,21.584v29.713C211.671,123.764,207.064,128.37,201.382,128.37"
                    />
                    <path
                        d="M350.41,88.37v40.002h-14.446V86.305c0-12.225-6.352-19.523-16.031-19.523&#xA;&#x9;&#x9;c-10.314,0-20.636,6.033-20.636,21.588v40.002H284.85V54.244h14.446v11.11c3.968-8.255,14.446-11.906,20.479-12.38&#xA;&#x9;&#x9;c2.855-0.162,5.554,0,8.095,0.475c9.363,1.428,15.874,6.348,19.36,14.447c6.828-13.17,18.42-15.235,25.72-15.235&#xA;&#x9;&#x9;c18.57,0,28.572,12.062,28.572,32.854v42.857h-4.157c-5.683,0-10.289-4.605-10.289-10.289V86.467&#xA;&#x9;&#x9;c0-12.225-6.347-19.686-16.986-19.686C357.866,66.625,350.41,75.993,350.41,88.37"
                    />
                    <path
                        d="M484.558,116.944c-4.92,8.414-15.712,13.014-26.032,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.32,0,21.112,4.287,26.032,12.695V54.245h14.289v74.125h-5.47&#xA;&#x9;&#x9;c-4.871,0-8.819-3.949-8.819-8.82V116.944z M459.796,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.605-9.045,24.605-24.762C484.402,75.196,471.702,66.784,459.796,66.784"
                    />
                    <path
                        d="M522.528,128.37h-3.996V2.113h14.284v77.999l25.557-25.871h19.685L546.31,86.303l39.365,42.066h-19.047&#xA;&#x9;&#x9;L532.816,92.18v25.9C532.816,123.764,528.209,128.37,522.528,128.37"
                    />
                    <path
                        d="M656.957,116.944c-4.92,8.414-15.712,13.014-26.033,13.014c-20.317,0-37.618-15.24-37.618-38.727&#xA;&#x9;&#x9;c0-23.652,17.301-38.575,37.618-38.575c10.321,0,21.113,4.287,26.033,12.695V54.245h14.289v74.125h-2.869&#xA;&#x9;&#x9;c-6.307,0-11.42-5.113-11.42-11.42V116.944z M632.195,66.784c-13.333,0-24.124,9.525-24.124,24.285&#xA;&#x9;&#x9;c0,14.604,10.791,24.762,24.124,24.762c13.175,0,24.604-9.045,24.604-24.762C656.799,75.196,644.099,66.784,632.195,66.784"
                    />
                    <path
                        d="M705.375,128.37h-14.441V54.241h14.441v11.111c4.444-8.889,15.079-12.695,23.177-12.695&#xA;&#x9;&#x9;c18.415,0,28.574,12.062,28.574,32.857v42.855h-2.688c-6.493,0-11.758-5.264-11.758-11.758V86.465&#xA;&#x9;&#x9;c0-12.383-6.19-19.682-16.35-19.682c-10.478,0-20.955,5.873-20.955,21.584V128.37z"
                    />
                </g>
            </svg>
        </div>
    );
};

export const PrimeLogo = ({
    className = '',
    withDahmakan = true,
    fillColor = 'none',
    leafColor = '#00AECB',
}) => {
    return (
        <div className="display--inline-block">
            <svg
                width="106px"
                className={`logo__text ${className}`}
                height="48px"
                viewBox="0 0 106 48"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    id="PW0---Prime-packages"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    transform="translate(-676.000000, -11.000000)"
                >
                    <g
                        id="Group-13"
                        transform="translate(676.000000, 11.000000)"
                    >
                        <g id="Group-4">
                            <path
                                d="M5.9337,6.8062 L5.9337,15.5572 L11.8487,15.5572 C14.5237,15.5572 15.7787,13.2882 15.7787,11.2222 C15.7787,9.1962 14.6037,6.8062 12.0117,6.8062 L5.9337,6.8062 Z M0.7477,2.1882 L12.0917,2.1882 C18.2907,2.1882 20.9637,6.6852 20.9637,11.2222 C20.9637,15.7592 18.2907,20.1752 12.0917,20.1752 L5.9337,20.1752 L5.9337,30.1012 L0.7477,30.1012 L0.7477,2.1882 Z"
                                id="Fill-3"
                                fill={`${fillColor}`}
                            ></path>
                            <path
                                d="M30.5604,30.101 L25.6194,30.101 L25.6194,11.182 L30.5604,11.182 L30.5604,15.638 C31.5324,12.194 33.7614,10.776 35.9084,10.776 C37.2454,10.776 38.2584,11.06 39.0274,11.465 L38.1764,15.922 C37.0024,15.395 35.7874,15.395 35.3014,15.395 C31.9384,15.395 30.5604,18.393 30.5604,23.417 L30.5604,30.101 Z"
                                id="Fill-4"
                                fill={`${fillColor}`}
                            ></path>
                            <polygon
                                id="Fill-5"
                                fill={`${fillColor}`}
                                points="42.265 30.1015 47.167 30.1015 47.167 11.1825 42.265 11.1825"
                            ></polygon>
                            <path
                                d="M70.0919,20.0135 L70.0919,30.1005 L65.1509,30.1005 L65.1509,19.6895 C65.1099,17.0555 63.6109,15.5975 61.6659,15.5975 C59.6399,15.5975 57.6949,16.7725 57.6949,20.0135 L57.6949,30.1005 L52.7539,30.1005 L52.7539,11.1815 L57.6949,11.1815 L57.6949,13.9765 C58.7499,11.6675 61.3429,10.8175 63.1249,10.8175 C66.1229,10.9785 68.1079,12.0325 69.1599,14.3415 C70.9429,11.2225 73.6179,10.7765 75.1169,10.7765 C79.6939,10.7765 82.4079,13.7345 82.4079,19.2845 L82.4079,30.1005 L77.5059,30.1005 L77.5059,19.8105 C77.5059,17.0965 75.9659,15.5975 73.9409,15.5975 C71.7139,15.5975 70.0919,17.1375 70.0919,20.0135"
                                id="Fill-6"
                                fill={`${fillColor}`}
                            ></path>
                            <path
                                d="M101.1577,18.9197 C100.7937,16.3267 98.8487,14.9087 96.4177,14.9087 C93.8257,14.9087 91.7997,16.3667 91.4347,18.9197 L101.1577,18.9197 Z M105.8987,22.2827 L91.4757,22.2827 C91.7997,24.7937 93.9057,26.3337 96.7017,26.3337 C98.6057,26.3337 100.5507,25.5227 101.4417,23.9027 C102.6167,24.5507 103.9937,25.2807 105.2097,25.9277 C103.5077,29.1697 99.8617,30.5057 96.3777,30.5057 C90.9897,30.5057 86.5737,26.5357 86.5737,20.5807 C86.5737,14.6257 90.9897,10.7767 96.3777,10.7767 C101.7657,10.7767 105.9787,14.6257 105.9787,20.5807 C105.9787,21.0667 105.9387,21.8367 105.8987,22.2827 L105.8987,22.2827 Z"
                                id="Fill-7"
                                fill={`${fillColor}`}
                            ></path>
                            <path
                                d="M49.6185,1.4729 C49.6155,1.0149 49.5595,0.5719 49.4595,0.1449 C49.0305,0.0479 48.5855,-0.0021 48.1295,-0.000100000001 C44.8725,0.0179 42.2485,2.6729 42.2665,5.9289 C42.2685,6.3849 42.3255,6.8299 42.4255,7.2559 C42.8535,7.3529 43.2995,7.4039 43.7555,7.4019 C47.0115,7.3839 49.6365,4.7289 49.6185,1.4729"
                                id="Fill-8"
                                fill={`${leafColor}`}
                            ></path>
                            {withDahmakan ? (
                                <text
                                    id="By-dahmakan"
                                    fontFamily="SofiaProRegular, Sofia Pro"
                                    fontSize="11.3367"
                                    fontWeight="normal"
                                    letterSpacing="-0.1247037"
                                    fill="#00AECB"
                                >
                                    <tspan x="17.5381" y="44.226077">
                                        B
                                    </tspan>
                                    <tspan
                                        x="24.4081402"
                                        y="44.226077"
                                        letterSpacing="-0.2834175"
                                    >
                                        y
                                    </tspan>
                                    <tspan x="29.6116855" y="44.226077">
                                        {' '}
                                        dahma
                                    </tspan>
                                    <tspan
                                        x="69.9363274"
                                        y="44.226077"
                                        letterSpacing="-0.3287643"
                                    >
                                        k
                                    </tspan>
                                    <tspan x="74.9698222" y="44.226077">
                                        an
                                    </tspan>
                                </text>
                            ) : null}
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export const BlackLogo = ({ className = '' }) => {
    return (
        <div className={className}>
            <BrandText className="logo__text logo__text--default-black" />
            <Leaf size="8" color="green" />
        </div>
    );
};

export const WhiteLogoWhiteLeaf = ({ className = '', withLeaf = true }) => {
    return (
        <div className={className}>
            <BrandText className="logo__text logo__text--default-white" />
            {withLeaf ? <Leaf size="8" color="white" /> : null}
        </div>
    );
};

export const WhitePopLogo = () => {
    return (
        <svg width="332" height="206" viewBox="0 0 332 206" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M181.336 158.231C161.917 158.466 147.356 145.715 141.513 127.819C130.534 94.1827 155.432 50.1841 186.003 50.0438C197.511 49.992 207.079 56.129 210.201 65.6957C212.619 73.1015 208.834 70.336 201.527 72.2244C168.467 78.9049 161.53 112.507 165.264 129.586C167.012 137.574 169.974 143.026 178.242 144.338C189.431 146.113 199.71 133.298 203.602 116.732C206.089 106.149 204.764 97.9951 203.019 90.3733C201.495 83.7121 199.159 77.8729 205.196 77.8446C211.67 77.8148 219.396 82.7248 223.021 93.8343C226.748 105.251 225.118 119.48 218.809 131.79C210.22 148.546 197.686 158.033 181.336 158.231Z" fill="white"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="332" height="206">
                <path d="M0.438858 0.143555H331.811V205.429H0.438858V0.143555Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M305.787 107.429C303.668 116.462 297.581 128.101 288.372 132.89C277.653 138.462 266.521 129.682 267.101 115.259C267.623 102.296 271.275 94.3208 273.68 90.3938C281.324 77.9142 294.926 72.3654 301.597 80.9284C307.291 88.2374 307.746 99.0774 305.787 107.429ZM328.214 75.4541C322.013 62.2165 307.573 55.1978 293.326 58.1807C283.77 60.1822 277.507 65.186 269.269 75.192C269.912 72.6588 271.217 67.8308 271.612 66.7064C273.344 61.7608 266.921 60.1241 264.487 59.6699C261.122 59.0415 256.77 59.8695 253.998 61.8248C249.778 64.8003 247.758 70.4429 246.21 74.9924C240.357 92.2077 238.85 111.307 238.223 129.29C237.629 146.334 239.351 169.874 243.99 186.485C247.365 198.573 257.713 210.254 273.536 203.389C279.088 200.981 275.608 194.808 273.536 188.406C271.159 181.052 264.315 157.773 265.504 139.094C272.731 151.451 289.59 155.81 304.086 148.081C316.855 141.271 325.423 127.631 328.918 115.052C332.091 103.636 333.72 87.2099 328.214 75.4541Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M137.102 47.968C136.056 33.3648 127.629 19.7564 113.982 10.6321C97.9414 -0.0947188 77.6616 -2.78125 59.7315 3.44066C36.0352 10.3238 17.496 24.6783 7.52455 43.8608C1.43163 55.5838 -0.737168 65.7775 1.08144 74.1602C3.02364 83.1148 10.8248 95.027 20.0415 98.6562C22.0867 99.4648 24.2878 99.097 25.9328 97.6674C27.6411 96.1856 28.3929 93.8699 27.903 91.6629C24.4717 73.362 33.2308 54.3552 42.5725 42.6679C52.7823 29.8979 74.5511 20.1436 89.462 26.5636C105.398 33.4274 113.672 50.6158 110.538 70.3553C109.178 78.9182 103.465 95.4112 80.5337 109.624C79.3316 110.269 77.0333 111.389 73.6933 112.959C73.8714 100.451 74.6541 87.6435 76.024 74.8646C76.8788 66.8973 78.7548 52.5443 81.6961 45.971C82.0051 45.3798 82.2581 44.4028 81.6843 43.0521C80.0688 39.7654 77.4012 37.5614 74.1745 36.8466C70.8874 36.1199 67.4753 37.0268 64.5679 39.4006C59.683 43.3887 58.1469 50.5026 55.9545 56.5816C51.767 68.1885 41.8809 122.89 49.404 166.798C51.1962 177.261 55.0614 185.045 61.3191 188.555C63.7175 189.9 66.3512 190.488 69.1424 190.572C74.2186 190.724 78.6474 188.7 79.5832 188.149C80.5205 187.598 82.2596 185.949 80.8383 182.111C75.6105 168.84 73.6433 155.044 73.3917 129.642C93.9231 124.868 109.222 115.733 120.14 101.733C135.726 81.7477 137.967 61.4692 137.102 47.968Z" fill="white"/>
            </g>
        </svg>
    )
}

export const Leaf = ({ size = '20', color = 'green', className = '' }) => {
    return (
        <div className="display--inline-block">
            <svg
                className={`logo logo--${color} ${className}`}
                viewBox="0 0 25.8 25.8"
                width={size}
            >
                <path d="M25.8 5.2c0-1.6-.2-3.1-.5-4.6-1.5-.3-3-.5-4.6-.5C9.2 0 0 9.2 0 20.6c0 1.6.2 3.1.5 4.6 1.5.3 3 .5 4.6.5 11.4.1 20.7-9.2 20.7-20.5" />
            </svg>
        </div>
    );
};

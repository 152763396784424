import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import HubPerformanceHeader from './HubPerformanceHeader';
import HubPerformanceData from './HubPerformanceData';
import Loading from '../Loading';
import {Helmet} from "react-helmet";

const base = 'performance';

@inject('HubPerformanceStore')
@observer
class HubPerformance extends Component {
    componentDidMount() {
        const {
            HubPerformanceStore: { date, handleGetDispatches },
        } = this.props;

        handleGetDispatches();
        // refresh only on today
        this.interval = setInterval(() => {
            handleGetDispatches();
        }, 1000 * 60 * 1); // 1 minute
    }

    componentDidUpdate() {
        const {
            HubPerformanceStore: { date, handleGetDispatches },
        } = this.props;

        clearInterval(this.interval);
        if (moment().format('DD-MM-YYYY') === date) {
            this.interval = setInterval(() => {
                handleGetDispatches();
            }, 1000 * 60 * 1); // 1 minute
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { HubPerformanceStore } = this.props;
        const { loading } = HubPerformanceStore;

        if (loading) {
            return <Loading />;
        }

        return (
            <Fragment>
                <Helmet>
                    <title>Outlet Performance</title>
                </Helmet>
                <HubPerformanceHeader />
                <div className={`container mtxl mbxl`}>
                    <div className={`row`}>
                        <div
                            className={`${base}__col col-xs-12 col-md-6 pll prl`}
                        >
                            <HubPerformanceData period="Today" />
                        </div>
                        <div
                            className={`${base}__col col-xs-12 col-md-6 pll prl `}
                        >
                            <HubPerformanceData period="Last hour" />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HubPerformance;

import React, { Component } from 'react';
import { observer } from 'mobx-react';

const base = 'cooking-v2';

@observer
class DeliverySubHeadingWrapper extends Component {
    render() {
        return(
            <div className={`${base}__sub_heading display--flex`} style={{margin : '1.75rem 0px 1.25rem 0px'}}>
                <img src='https://image.popcontent.link/delivery_icon.png' style={{height: '3rem'}}/>
                <p className={`${base}__delivery_heading`}>Delivery</p>
                <img src='https://image.popcontent.link/delivery_icon.png' style={{height: '3rem'}}/>
            </div>
        )
    }
}
export default DeliverySubHeadingWrapper;

import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import Loading from '../Loading';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const base = 'sku-entry';

@inject('StockTransferStoreItems')
@observer
class InventoryReceiveStockUpdate extends Component {
    initialState = {
        openSkuList: false,
        showConfirmationModel: false,
        pallets: '',
        boxes: '',
        errorMessage: '',
    };

    state = this.initialState;

    componentDidMount = () => {
        const {
            match: {
                params: { itemType },
            },
            StockTransferStoreItems: {
                handleGetHubStockTransfers,
                handleSetMealType,
            },
        } = this.props;

        handleSetMealType(itemType);
        handleGetHubStockTransfers();
    };

    handleOpenSkuList = () => {
        this.setState({ openSkuList: true });
    };

    handleCloseSkuList = () => {
        this.setState({ openSkuList: false });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModel: false });
    };

    handleSetCurrentSku = (sku) => {
        const {
            StockTransferStoreItems: { handleSetCurrentSku },
        } = this.props;

        handleSetCurrentSku(sku);
        this.setState(this.initialState);
    };

    handleSetQuantity = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleGetTotalInputQuantity = () => {
        const { pallets, boxes } = this.state;
        const {
            StockTransferStoreItems: { hubStockTransfers, currentSku },
        } = this.props;
        const {
            stock_transfer_plan: { meals_per_pallet },
        } = hubStockTransfers[currentSku][0];

        return pallets
            ? (parseInt(pallets) || 0) * meals_per_pallet +
                  (parseInt(boxes) || 0)
            : parseInt(boxes) || 0;
    };

    handleSubmit = () => {
        const {
            StockTransferStoreItems: { totalQuantity },
        } = this.props;
        const totalInputQuantity = this.handleGetTotalInputQuantity();

        if (Math.abs(totalQuantity - totalInputQuantity) > 0) {
            const errorMessage = {
                totalQuantity,
                totalInputQuantity,
            };
            this.setState({ showConfirmationModel: true, errorMessage });
        } else {
            this.sendRequest();
        }
    };

    sendRequest = () => {
        const {
            StockTransferStoreItems: {
                handleValidateTransferPlan,
                hubStockTransfers,
                currentSku,
            },
        } = this.props;
        const stock_transfer_plans = hubStockTransfers[currentSku].map(
            (transfer) => transfer.stock_transfer_plan
        );
        const totalExistingQuantity = stock_transfer_plans
            .map(
                (p) =>
                    p.quantity +
                    (p.emergency_boxes_quantity || 0) -
                    (p.emergency_boxes_quantity_used || 0) +
                    (p.quality_assurance_boxes_quantity || 0) -
                    (p.quality_assurance_boxes_quantity_used || 0)
            )
            .reduce((a, b) => a + b);
        let totalInputQuantity = this.handleGetTotalInputQuantity();
        let delta = totalInputQuantity - totalExistingQuantity;

        let plans_with_quantity = stock_transfer_plans.filter(
            (p) => p.quantity > 0
        );
        // loop in reverse to deduct latest batch first if delta < 0
        for (let i = plans_with_quantity.length - 1; i >= 0; i--) {
            let stock_transfer_plan = plans_with_quantity[i];
            if (delta < 0) {
                let to_adjust = Math.min(
                    stock_transfer_plan.quantity,
                    Math.abs(delta)
                );
                stock_transfer_plan.quantity =
                    stock_transfer_plan.quantity - to_adjust;
                delta += to_adjust;
            } else if (delta > 0) {
                stock_transfer_plan.quantity += delta;
                delta = 0;
            }
            if (delta === 0) {
                break;
            }
        }
        stock_transfer_plans.forEach((p) => handleValidateTransferPlan(p));
        this.setState(this.initialState);
    };

    render() {
        const {
            openSkuList,
            pallets,
            boxes,
            showConfirmationModel,
            errorMessage,
        } = this.state;
        const {
            StockTransferStoreItems: {
                hubStockTransfers,
                loading,
                currentSku,
                totalSkusReceived,
                totalSkus,
                skusByLetter,
                isSkuReceived,
                itemType,
            },
        } = this.props;

        if (loading) {
            return <Loading />;
        }

        if (!Object.keys(hubStockTransfers).length) {
            return (
                <Fragment>
                    <InventoryHeader title="Receive New Stock" />
                    <div className="no-data">No data</div>
                </Fragment>
            );
        }
        // Use data from first stock transfer plan for display
        const {
            stock_transfer_plan,
            inventory_batch: { stock_check_tracker },
        } = hubStockTransfers[currentSku][0];
        const {
            menu_item_name: itemName,
            arrival_at,
            expires_at,
            meals_per_pallet,
        } = stock_transfer_plan;
        const isReceived = isSkuReceived(currentSku);

        const {
            StockTransferStoreItems: { totalQuantity },
        } = this.props;
        const batchPallets =
            itemType === 'Item'
                ? Math.floor(totalQuantity / meals_per_pallet)
                : '';
        const batchBoxes =
            itemType === 'Item'
                ? totalQuantity % meals_per_pallet
                : totalQuantity;

        const lastUpdatedLog =
            stock_check_tracker &&
            stock_check_tracker.length &&
            stock_check_tracker[stock_check_tracker.length - 1];

        const startDate = moment(arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = moment(expires_at, 'x')
            .subtract(1, 'days')
            .format('DD/MM/YYYY');

        return (
            <div className="mbxl pbxl">
                <InventoryHeader title="Receive New Stock" />
                <div
                    className={`${base}__progress fs--medium-medium-large text--bolder ps mbs`}
                >
                    <span className={`text--red ms`}>
                        {`${totalSkusReceived} out of ${totalSkus} `}
                        <span className={`text--black`}>SKUs completed</span>
                    </span>
                    <button
                        className="btn width--300px ms"
                        onClick={this.handleOpenSkuList}
                    >
                        Jump to SKU
                    </button>
                </div>
                <div className={`pl`}>
                    <span className={`fs--large text--bolder mrs`}>
                        {currentSku.split('_')[0]}
                    </span>
                    <span className={`fs--medium`}>{itemName}</span>
                </div>
                <Fragment>
                    <div
                        className={`fs--medium-large ps bg--black text--white`}
                    >
                        {`${startDate} - ${endDate}`}
                    </div>
                    {itemType === 'Item' && (
                        <div className={`${base} grid grid-1-2 pl pb0`}>
                            <label className="fs--medium-medium-large">
                                No. of Pallets:
                            </label>
                            <input
                                className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps"
                                name="pallets"
                                type="number"
                                value={isReceived ? batchPallets : pallets}
                                onChange={this.handleSetQuantity}
                                disabled={isReceived}
                            />
                        </div>
                    )}
                    <div className={`${base} grid grid-1-2 pl`}>
                        <label className="fs--medium-medium-large">
                            {itemType === 'Item'
                                ? 'Loose meals:'
                                : 'Actual Stock Received:'}
                        </label>
                        <input
                            className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps"
                            name="boxes"
                            type="number"
                            value={isReceived ? batchBoxes : boxes}
                            onChange={this.handleSetQuantity}
                            disabled={isReceived}
                        />
                    </div>
                    {isReceived && lastUpdatedLog && (
                        <div className="text--left pl">
                            <h3>Last updated</h3>
                            <p>User: {lastUpdatedLog.initiator_username}</p>
                            <p>
                                Time:{' '}
                                {moment(
                                    lastUpdatedLog.updated_at,
                                    'x'
                                ).calendar()}
                            </p>
                        </div>
                    )}
                </Fragment>
                {openSkuList && (
                    <div className={`production__drawer-mask`}></div>
                )}
                <div
                    className={`production__drawer ${
                        openSkuList ? 'open' : ''
                    }`}
                >
                    <div
                        className={`production__nav-btn drawer-close`}
                        onClick={this.handleCloseSkuList}
                    >
                        <svg className="icon">
                            <use xlinkHref={`#icon-cross`} />
                        </svg>
                    </div>
                    <p className="fs--medium-large text--sofiapro text--left mbm">
                        Select SKU
                    </p>
                    <div className={`production__drawer-buttons`}>
                        {Object.keys(skusByLetter).map((letter) => (
                            <Fragment key={letter}>
                                <h3 className="width--100 text--left fs--medium-large text--bolder">{`Type ${letter}`}</h3>
                                {skusByLetter[letter].map((sku) => (
                                    <div
                                        key={sku}
                                        className={`production__drawer-button ${
                                            isSkuReceived(sku)
                                                ? 'selected'
                                                : 'unchecked'
                                        } ${
                                            currentSku === sku ? 'current' : ''
                                        } ${
                                            itemType === 'Addons'
                                                ? 'font-small'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            this.handleSetCurrentSku(sku)
                                        }
                                    >
                                        {itemType === 'Item'
                                            ? sku.split('_')[0]
                                            : sku}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className="footer-bottom">
                    <button
                        className={`btn width--75 ${
                            !isReceived ? 'btn-selected' : ''
                        }`}
                        onClick={this.handleSubmit}
                        disabled={isReceived}
                    >
                        Submit and Next
                    </button>
                </div>
                {showConfirmationModel && (
                    <Modal>
                        <h3>
                            Attention! Big difference in count, are you sure
                            your count is correct?
                        </h3>
                        <div className="mbm">
                            <div className="modal-content">{`Actual Count = ${errorMessage.totalQuantity} meals`}</div>
                            <div className="modal-content">{`Your Count = ${errorMessage.totalInputQuantity} meals`}</div>
                        </div>
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.sendRequest}
                            >
                                Yes, save count
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryReceiveStockUpdate;

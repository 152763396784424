import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';
class WarmerScannerView extends Component {
    render() {
        const {
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={`${base}__warmer ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://image.popcontent.link/dine_in_icon_white.png"
                            alt="dine in icon"
                        />
                        <p className={`${base}__order_type_text`}>WARMER</p>
                    </div>
                    <p className={`${base}__sku_text`}>{scannedItemSKU}</p>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default WarmerScannerView;

import { observable, action, computed, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import moment from 'moment';

class StockTransferStoreItems {
    @observable loading = false;
    @observable hubStockTransfers = {};
    @observable currentSku = '';
    @observable selectedBatch = null;

    @computed get skuList() {
        return Object.keys(this.hubStockTransfers);
    }

    @computed get skusByCategory() {
        return this.skuList.reduce((acc, sku) => {
            const category = this.hubStockTransfers[sku]
                .menu_item_line_names[0];
            acc[category] = acc[category] ? acc[category].concat([sku]) : [sku];
            return acc;
        }, {});
    }

    isSkuReceived = (sku) => {
        const { inventory_batches } = this.hubStockTransfers[sku];
        return inventory_batches
            .filter(this.isBatchValid)
            .every(
                (batch) =>
                    batch.stock_update_tracker &&
                    batch.stock_update_tracker.length
            );
    };

    isBatchValid = (batch) => {
        return (
            batch.hub_code === UserStore.selectedHub.value &&
            moment(batch.expires_at, 'x').isAfter() &&
            batch.quantity_left
        );
    };

    @action handleSetCurrentSku = (sku) => {
        this.currentSku = sku;
    };

    @action handleSelectBatch = (selectedBatch) => {
        this.selectedBatch = selectedBatch;
    };

    @action handleUpdateTransferPlanAddons = (transferPlan) => {
        api.updateTransferPlanAddons(transferPlan)
            .then((res) => {
                this.hubStockTransfers[this.currentSku] = res;
                this.selectedBatch = res.inventory_batches.find(
                    (batch) => batch.id === transferPlan.id
                );
            })
            .catch((err) => {
                UserStore.message = err.message;
            })
            .finally(() => this.handleGetHubStockTransfersAddons());
    };

    @action handleGetHubStockTransfersAddons = () => {
        this.loading = true;
        api.getHubStockTransfersAddons()
            .then((res) => {
                const hubStockTransfers = res.list.filter(
                    (item) =>
                        item.item_type === 'Addons' &&
                        item.status === 'Available' &&
                        item.inventory_batches &&
                        item.inventory_batches.some(this.isBatchValid)
                );

                this.hubStockTransfers = hubStockTransfers.reduce(
                    (acc, item) => {
                        const { sku } = item;
                        acc[sku] = acc[sku] || item;
                        return acc;
                    },
                    {}
                );

                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };
}

const store = new StockTransferStoreItems();
export default store;

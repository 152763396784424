import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import Loading from '../Loading';

const base = 'print-stock';

@inject('ComponentStockStore')
@observer
class Inventory extends Component {
    componentDidMount() {
        const {
            ComponentStockStore: {
                handleGetStockCountList
            }
        } = this.props;

        handleGetStockCountList();
    }
    

    render() {
        const {
            ComponentStockStore: { componentStockPrintLoading, stockCountList },
        } = this.props;

        if (componentStockPrintLoading) {
            return <Loading />;
        }

        let sortedStockCountList = stockCountList.sort((a, b) => {
            const categoryA = a.category_name ? a.category_name : "";
            const categoryB = b.category_name ? b.category_name : "";

            if (categoryA < categoryB) {
                return -1;
            }
            if (categoryA > categoryB) {
                return 1;
            }
            return 0;
        });

        for (var i = 0; i< sortedStockCountList.length; i ++) {
            sortedStockCountList[i].category_detail.uoms = sortedStockCountList[i].category_detail.uoms.sort((a, b) => {
                const numA = parseInt(a.match(/\d+/)?.[0] || 1);
                const numB = parseInt(b.match(/\d+/)?.[0] || 1);
                const isPortionA = a === "Portion" || a === "Unit";
                const isPortionB = b === "Portion" || b === "Unit";

                const rankA = isPortionA ? 0 : numA;
                const rankB = isPortionB ? 0 : numB;

                if (isPortionA && isPortionB) {
                    return b.localeCompare(a);
                }
                return rankB - rankA;
            });
        }

        return (
            <Fragment>
                <InventoryHeader title="Print Stock Count" backLink="" />
                <div style={{backgroundColor: "white"}}>
                <button className="btn btn-primary" onClick={() => window.print()} style={{position: 'absolute', right: '10px'}}>
                    Print
                </button>
                    {sortedStockCountList.map((category, index) => (
                        <div key={index}>
                            <div style={{textAlign: 'left', marginTop: '30px', marginBottom: '10px', fontSize: "20px", paddingLeft: '10px'}}> {category.category_name ? category.category_name : "No Category"} </div>
                            <table className={`${base}__data_table`}>
                                <thead>
                                    <tr>
                                        <th>
                                            Item Code
                                        </th>
                                        <th>
                                            Item Name
                                        </th>
                                        {category.category_detail.uoms.map((uom, index) => (
                                            <th key = {index}>
                                                {uom}
                                            </th>
                                        ))}
                                    </tr> 
                                </thead>
                                <tbody>
                                    {category.category_detail.unit_skus.map((unitSku, index) => (
                                        <tr>
                                            <td>
                                                {unitSku.sku}
                                            </td>
                                            <td>
                                                {unitSku.name}
                                            </td>
                                            {category.category_detail.uoms.map((uom, index) => (
                                                <td key = {index}>
                                                    {unitSku.uoms.includes(uom) ? "" : "XXXX"}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody> 
                            </table>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}

export default Inventory;

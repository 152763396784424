import React from 'react';

const base = 'orders-kitchen';

export default function WarmerSKU({ item, isCurrentStock }) {
    return (
        <div className={`${base}__sku_item  ${isCurrentStock ? 'current-stock' : item.status != 'COMPLETED' ? 'in_production' : 'on_route'}`}>
            {item.oos && (
                <p className="sku-tag">
                    OOS
                </p>
            )}
            
            <p className="sku">
                {item.name}
            </p>

            <p className="qty">
                x{item.quantity}
            </p>
        </div>
    );
}

import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

@inject('InventoryStore')
@observer
class LiveInventoryAdjustment extends Component {
    render() {
        return (
            <Fragment>
                <InventoryHeader title="Current Stock" backLink="" />
                <div className="inventory-menu">
                    <h2>{moment().format('dddd - DD/MM/YYYY')}</h2>
                    <div className="btn-wrapper mm mtxl">
                        <Link
                            to={`/inventory/live`}
                        >
                            <button className="btn btn-primary width--300px">
                                Items
                            </button>
                        </Link>
                    </div>
                    <div className="btn-wrapper mm">
                        <Link
                            to={`/inventory/live/adjustment/component`}
                        >
                            <button className="btn btn-primary width--300px">
                                Component
                            </button>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LiveInventoryAdjustment;

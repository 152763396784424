export default {
    // Base URL
    baseUrl: 'https://attendance.dahmakan.com/',

    // Hostname for static endpoints
    staticAssets: 'https://attendance.dahmakan.com/',

    // Hostname for API endpoints
    services: 'https://api.dahmakan.com',

    // Webhook for tracking picking
    pickingTracking:
        'https://script.google.com/a/macros/dahmakan.com/s/AKfycbwud--ndR7jEAIaPSjHgMeUm0s-q_Nb6Xap5GU4I94rI8DltO8/exec',

    warmerStockPlanner: {
        url:
            'https://morh1czwm5.execute-api.ap-southeast-1.amazonaws.com/warmer_prod',
        token: 'gjzs1yku2j3JOHVbYOk5k7vjlVWVyCOI84PemoXu',
    },
};

import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Loading from '../Loading';
import Modal from '../Modal';

@inject('StockTransferStoreItems')
@observer
class InventoryReceiveStockOdoo extends Component {
    state = { showConfirmationModal: false };

    componentDidMount = () => {
        const {
            StockTransferStoreItems: { handleGetReceiptProducts, receipt_id }
        } = this.props;
        handleGetReceiptProducts(receipt_id);
    };

    handleShowConfirmationModal = () => {
        this.setState({ showConfirmationModal: true });
    };

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    setQuantity = (id, event) => {
        const {
            StockTransferStoreItems
        } = this.props;
        let receiptProducts = StockTransferStoreItems.receiptProducts;
        receiptProducts.filter(p => p.id == id)[0].done_value = parseFloat(event.target.value);
        StockTransferStoreItems.receiptProducts = receiptProducts;
    }

    saveDoneValue = () => {
        this.setState({ showConfirmationModal: false });
        const {
            StockTransferStoreItems: { handleSaveDoneValue }
        } = this.props;
        handleSaveDoneValue();
    }
    
    render() {
        const { showConfirmationModal } = this.state;

        const {
            StockTransferStoreItems: { receiptProducts, receipt_id, loading }
        } = this.props;

        if (loading) {
            return <Loading />
        }

        if (!receipt_id){
            return( <Redirect to="/inventory/receive-stock" /> ) 
        }

        return (
            <Fragment>
            <InventoryHeader title="Receive New Stock" />
                <table class="fs--medium table table-striped table-bordered" >
                    <thead>
                        <tr>
                            <th> Product </th>
                            <th> Demand </th>
                            <th> UOM </th>
                            <th> Done </th>
                        </tr>
                    </thead>
                    <tbody>
                    {receiptProducts.map((product, index) => (
                        <tr key={product.id}>
                            <td> {product.product_name} </td>
                            <td> {product.demand} </td>
                            <td> {product.uom} </td>
                            <td> 
                                <input
                                    className='border--rounded border--solid text--center center--horizontal ps'
                                    type='number'
                                    name='inputNumber'
                                    min={0}
                                    defaultValue={product.warehouse_quantity}
                                    value={product.done}
                                    onChange={this.setQuantity.bind(this, product.id)}
                                    />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                    <div className="row col-xs-12 text--center mtm" >
                        <button className="btn btn-primary" onClick={this.handleShowConfirmationModal}>
                            Confirm receipt
                        </button>
                    </div>

                {showConfirmationModal && (
                    <Modal>
                        <h3>
                            After saving, you cannot make any more changes. Are you sure the quantities are correct?
                        </h3>
                        <div className="display--flex justify--space-around mtl">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.saveDoneValue}
                            >
                                Confirm
                            </button>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default InventoryReceiveStockOdoo;

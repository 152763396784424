import { observable, action, computed, toJS } from 'mobx';
import moment from 'moment';
import * as api from '../services/api';
import UserStore from './common/UserStore';

class HubPerformanceStore {
    @observable date = moment().format('DD-MM-YYYY');
    @observable loading = true;
    @observable dispatchList = [];
    @observable routeList = [];

    @computed get routeListHour() {
        return this.routeList.filter((route) =>
            moment(route.actual_start_date, 'x').isAfter(
                moment().subtract(1, 'hours')
            )
        );
    }

    @computed get initiatedRoutes() {
        return this.routeList.filter((route) => route.status !== 'Pending');
    }

    @computed get initiatedRoutesHour() {
        return this.routeListHour.filter((route) => route.status !== 'Pending');
    }

    @action totalInitiatedRoutes = (filterLastHour = false) => {
        return filterLastHour
            ? this.initiatedRoutesHour.length
            : this.initiatedRoutes.length;
    };

    @action onTimeRoutes = (filterLastHour = false) => {
        const routes = filterLastHour
            ? this.initiatedRoutesHour
            : this.initiatedRoutes;
        return routes.reduce((acc, route) => {
            const { actual_start_date, start_date } = route;
            const buffered_start_date = start_date + 3 * 60 * 1000;
            if (actual_start_date < buffered_start_date) acc += 1;
            return acc;
        }, 0);
    };

    @action onTimeRoutesPercentage = (filterLastHour = false) => {
        return Math.floor(
            (this.onTimeRoutes(filterLastHour) /
                this.totalInitiatedRoutes(filterLastHour)) *
                100
        );
    };

    @action getDeliveriesByStatus = (
        deliveryStatus,
        filterLastHour = false
    ) => {
        return this.routeList.reduce((acc, route) => {
            const { order } = route;
            order.forEach((delivery) => {
                const { cancelled_at, delivered_at, status } = delivery;
                if (filterLastHour) {
                    if (
                        status === 'Cancelled' &&
                        deliveryStatus === 'Cancelled' &&
                        cancelled_at &&
                        moment(cancelled_at, 'x').isAfter(
                            moment().subtract(1, 'hours')
                        )
                    ) {
                        acc.push(delivery);
                    } else if (
                        status === 'Delivered' &&
                        deliveryStatus === 'Delivered' &&
                        delivered_at &&
                        moment(delivered_at, 'x').isAfter(
                            moment().subtract(1, 'hours')
                        )
                    ) {
                        acc.push(delivery);
                    }
                } else {
                    if (status === deliveryStatus) {
                        acc.push(delivery);
                    }
                }
            });
            return acc;
        }, []);
    };

    @action totalCompletedDeliveries = (filterLastHour = false) => {
        return this.getDeliveriesByStatus('Delivered', filterLastHour).length;
    };

    @action totalCancelledDeliveries = (filterLastHour = false) => {
        return this.getDeliveriesByStatus('Cancelled', filterLastHour).length;
    };

    @action onTimeDeliveries = (filterLastHour = false) => {
        return this.getDeliveriesByStatus('Delivered', filterLastHour).reduce(
            (acc, delivery) => {
                const { delayed } = delivery;
                if (!delayed) acc += 1;
                return acc;
            },
            0
        );
    };

    @action onTimeDeliveriesPercentage = (filterLastHour = false) => {
        return Math.floor(
            (this.onTimeDeliveries(filterLastHour) /
                this.totalCompletedDeliveries(filterLastHour)) *
                100
        );
    };

    @action handleGetDispatches = (date = this.date) => {
        this.loading = true;
        this.date = date;
        api.getDispatchListForDate(this.date)
            .then((res) => {
                this.dispatchList = res.dispatches || [];
                this.handleGetRoutes();
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetRoutes = async () => {
        this.routeList = [];
        for (const dispatch of this.dispatchList) {
            await api
                .getRoutesForDateAndDispatch(this.date, dispatch.dispatch_no)
                .then((res) => {
                    const routes = res.routes.filter((route) => route.order);
                    this.routeList = this.routeList.concat(routes);
                })
                .catch((err) => {
                    UserStore.message = err.message;
                });
        }
        this.loading = false;
    };
}

const store = new HubPerformanceStore();
export default store;

import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'live-dispatch';

@inject('LiveDispatchStore', 'ProductionStore', 'UserStore')
@observer
class PrintStickersModal extends Component {
    state = {
        isPrintError: false,
    };

    handlePrintErrorCallback = () => {
        this.setState({ isPrintError: true });
    };

    handlePrintSuccessCallback = () => {
        const { handleModalState, sendToastNotification } =
            this.props;
        handleModalState(false);
        if (sendToastNotification) {
            sendToastNotification();
        }
    };

    handleDismissBtnClick = () => {
        const { handleModalState } = this.props;
        this.setState({ isPrintError: false });
        handleModalState(false);
    };

    handlePrintBtnClick = () => {
        const {
            ProductionStore,
            category,
            UserStore: { selectedHub }
        } = this.props;
        let print_mode = null;
        let printer_id = null;
        print_mode = 'WARMER_STICKER';
        printer_id = selectedHub.kitchenPrinterId;

        ProductionStore.sendInstaServicePrintRequest({print_mode, printer_id, category},
            this.handlePrintSuccessCallback,
            this.handlePrintErrorCallback);
    };

    render() {
        const {
            handleModalState,
        } = this.props;
        const { isPrintError } = this.state;

        return (
            <div className={`${base}__modal`} style={{}}>
                {isPrintError ? (
                    <div className={`${base}__printer_fail`}>
                        <h3 className={`print_text`}>Print Failed</h3>
                        <p className="no_printer">No printer detected</p>
                        <button
                            className={`${base}__dismiss_button`}
                            onClick={this.handleDismissBtnClick}
                        >
                            Dismiss
                        </button>
                    </div>
                ) : (
                    <Fragment>
                        <h3 className={`${base}__print_text`}>Print TOP UP Sticker</h3>
                        <div className={`${base}__modal_order`}>
                            <p className="order_type_text"></p>
                            <button
                                className={`${base}__print_bag_qr_btn`}
                                onClick={() =>
                                    this.handlePrintBtnClick()
                                }
                            >
                                QR
                            </button>
                        </div>
                        <button
                            className={`${base}__cancel_button`}
                            onClick={() => handleModalState(false)}
                        >
                            Cancel
                        </button>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default PrintStickersModal;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SalePlatformIcon from './SalePlatformIcon';
import moment from 'moment';

const base = 'cooking-v2';

@inject('ProductionStore')
@observer
class OrderCategoryItems extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        appearTimestamp: null,
        isNewOrder: false
    };

    componentDidMount = () => {
        this.setCountdown();
    };

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { item, ProductionStore } = this.props;
        const now = moment((item.pick_up_type == "DINE_IN" || item.pick_up_type == "TAKE_AWAY") ? 
            (item.production_started_at ? item.production_started_at : item.order_date) : item.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const hours = moment.utc(duration).format('H');
        let minutes = moment.utc(duration).format('m');
        if (hours > 0) {
            minutes = moment.utc(duration).format('mm');
        }
        const seconds = moment.utc(duration).format('ss');
        this.setState({ hours, minutes, seconds});
        if (this.state.appearTimestamp == null) {
            ProductionStore.playAudio();
            this.setState({ hours, minutes, seconds, isNewOrder: true, appearTimestamp: then });
        }
    }

    render() {
        const {
            hours,
            minutes,
            seconds,
        } = this.state;
        const {item} = this.props;
        let isPriorityOrder = item.pick_up_type != null && 
            !item.platform.includes('GrabFood') &&
            !item.platform.includes('FoodPanda') &&
            !item.platform.includes('ShopeeFood');

        return(
            <div className={`${base}__items display--flex`} style={isPriorityOrder ? {backgroundColor: '#FFDE66'} : {backgroundColor: '#FFFCF0'}}>
                <div className='display--flex' style={{alignItems: 'center'}}>
                    <SalePlatformIcon
                        key={item.order_number ? item.order_number : item.pick_up_number}
                        item={item}
                    />
                    <div style={{padding: '0px 7px'}}>
                        {item.sku.length > 19 ? 
                            item.sku.substring(0, 17) + '...' : 
                            item.sku}
                        {item.not_served_by_insta_service &&
                            item.not_served_by_insta_service.length > 0 &&
                            item.not_served_by_insta_service.map((component) => (
                            <div className='display--flex' style={{alignItems: 'center', justifyContent: 'space-between', marginTop:'2px'}} >
                                <div className={`${base}__component_name`}>
                                    {component}
                                </div>
                                <div className={`${base}__cook_tag`}> COOK </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`${base}__timer`}>
                    {`${
                        parseInt(hours) > 0 ? `${hours}:` : ``
                    }${minutes}:${seconds}`}
                </div>
            </div>
        )
    }

}

export default OrderCategoryItems;
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

const base = 'performance';

@inject('HubPerformanceStore')
@observer
class HubPerformanceData extends Component {
    render() {
        const { HubPerformanceStore, period } = this.props;
        const {
            onTimeDeliveries,
            onTimeDeliveriesPercentage,
            onTimeRoutes,
            onTimeRoutesPercentage,
            totalCancelledDeliveries,
            totalCompletedDeliveries,
            totalInitiatedRoutes,
        } = HubPerformanceStore;

        const filterLastHour = period === 'Last hour' ? true : false;

        const score = (percentage) =>
            percentage >= 90 ? '👍👍' : percentage >= 80 ? '👍' : '👎👎';

        const color = (percentage) =>
            percentage >= 90 ? 'green' : percentage >= 80 ? 'yellow' : 'red';

        const icon = (percentage) =>
            percentage >= 90
                ? 'icon-check-circle'
                : percentage >= 80
                ? 'icon-caution-square'
                : 'icon-caution-triangle';

        return (
            <div className={`${base}`}>
                <h1 className="fs--larger text--sofiapro mt0 mbxl">{period}</h1>
                <div className={`row`}>
                    <div className={`col-xs-12 col-md-6`}>
                        <div
                            className={`${base}__card ${color(
                                onTimeRoutesPercentage(filterLastHour)
                            )}`}
                        >
                            <svg className={`iconmoon-icon`}>
                                <use
                                    xlinkHref={`#${icon(
                                        onTimeRoutesPercentage(filterLastHour)
                                    )}`}
                                />
                            </svg>
                            <h1 className={`${base}__card-header`}>
                                Dispatch<br></br>on-time
                            </h1>
                            {!isNaN(onTimeRoutesPercentage(filterLastHour)) && (
                                <h1 className={`fs--largest text--sofiapro m0`}>
                                    {`${onTimeRoutesPercentage(
                                        filterLastHour
                                    )}`}
                                    <span className={`text--thin`}>%</span>
                                </h1>
                            )}
                        </div>
                    </div>
                    <div className={`col-xs-12 col-md-6`}>
                        <div
                            className={`${base}__card ${color(
                                onTimeDeliveriesPercentage(filterLastHour)
                            )}`}
                        >
                            <svg className={`iconmoon-icon`}>
                                <use
                                    xlinkHref={`#${icon(
                                        onTimeDeliveriesPercentage(
                                            filterLastHour
                                        )
                                    )}`}
                                />
                            </svg>
                            <h1 className={`${base}__card-header`}>
                                Delivery<br></br>on-time
                            </h1>
                            {!isNaN(
                                onTimeDeliveriesPercentage(filterLastHour)
                            ) && (
                                <h1 className={`fs--largest text--sofiapro m0`}>
                                    {`${onTimeDeliveriesPercentage(
                                        filterLastHour
                                    )}`}
                                    <span className={`text--thin`}>%</span>
                                </h1>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${base}__card rectangle row mtl mbxl m0`}>
                    <div className={`col-xs-12 col-md-7`}>
                        <h1 className={`${base}__card-header`}>
                            Cancellations
                        </h1>
                    </div>
                    <div className={`col-xs-12 col-md-5`}>
                        <h1 className={`fs--largest m0`}>
                            {totalCancelledDeliveries(filterLastHour)}
                        </h1>
                        <h1 className={`m0`}>Orders</h1>
                    </div>
                </div>
                <table className={`${base}__table`}>
                    <thead>
                        <tr>
                            <th></th>
                            <td>Routes</td>
                            <td>Deliveries</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className={`text--left`}>Total</th>
                            <td>{totalInitiatedRoutes(filterLastHour)}</td>
                            <td>{totalCompletedDeliveries(filterLastHour)}</td>
                        </tr>
                        <tr>
                            <th className={`text--left`}>On-time</th>
                            <td>{onTimeRoutes(filterLastHour)}</td>
                            <td>{onTimeDeliveries(filterLastHour)}</td>
                        </tr>
                        <tr>
                            <th className={`text--left`}>Percentage</th>
                            <td
                                className={`${color(
                                    onTimeRoutesPercentage(filterLastHour)
                                )}`}
                            >
                                {!isNaN(onTimeRoutesPercentage(filterLastHour))
                                    ? `${onTimeRoutesPercentage(
                                          filterLastHour
                                      )}%`
                                    : ''}
                            </td>
                            <td
                                className={`${color(
                                    onTimeDeliveriesPercentage(filterLastHour)
                                )}`}
                            >
                                {!isNaN(
                                    onTimeDeliveriesPercentage(filterLastHour)
                                )
                                    ? `${onTimeDeliveriesPercentage(
                                          filterLastHour
                                      )}%`
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <th className={`text--left`}>Score</th>
                            <td
                                className={`${color(
                                    onTimeRoutesPercentage(filterLastHour)
                                )}`}
                            >
                                {score(onTimeRoutesPercentage(filterLastHour))}
                            </td>
                            <td
                                className={`${color(
                                    onTimeDeliveriesPercentage(filterLastHour)
                                )}`}
                            >
                                {score(
                                    onTimeDeliveriesPercentage(filterLastHour)
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default HubPerformanceData;

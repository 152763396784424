import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import QrReader from 'react-qr-reader';
import Webcam from "react-webcam";
import BagScanningStore from "../../stores/BagScanningStore";

const audio = new Audio(`${process.env.PUBLIC_URL}/qrcode_scan_sound.mp3`);
const base = 'bag_scan';

@inject('BagScanningStore')
@observer
class BagScanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            requestImage: null,
            error: null,
            isImageUploaded: false
        }
        this.webcamRef = React.createRef();
    }

    handleScan = data => {
        const { processing } = this.state;
        // Block next scan if browser is busy
        if (processing) {
            return;
        }

        if (data) {
            this.setState({
                processing: true
            });

            this.playAudio();
            this.handleSubmit(data);
        }
    }

    playAudio() {
        audio.play();
    }

    handleError = err => {
        console.error(err)
    }

    handleSubmit = (data) => {
        const {BagScanningStore} = this.props;
        const {handleScanBarcode, setBagImage} = BagScanningStore;
        if (this.webcamRef.current) {
            const image = this.webcamRef.current.getScreenshot();
            this.setState({requestImage: image})
            setBagImage(image);
        }

        handleScanBarcode(data);
    }

    render() {
        const {requestImage} = this.state;
        const {BagScanningStore: {bagImage, error}} = this.props;
        const videoConstraints = {
            facingMode: { exact: "environment" },
        };
        if (requestImage != null && bagImage == null){
            if (error == null){
                setTimeout(() => {this.setState({isImageUploaded: true})}, 1000);
            } else {
                setTimeout(() => {this.setState({isImageUploaded: false, error: error})}, 1000);
            }
            // // Wait 4s before next scan
            setTimeout(() => {this.setState({processing: false, requestImage: null, error: null})}, 4000);
        }
        return (
            <Fragment>
                {requestImage == null && (
                    <div className={`${base}__qr-reader`}>
                    <Webcam
                        style={{display: "none"}}
                        audio={false}
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        className="webcam"
                        screenshotQuality={1}
                        minScreenshotHeight={window.innerHeight}
                        minScreenshotWidth={window.innerWidth}
                        videoConstraints={videoConstraints}
                    />
                        <div className={`${base}__scan-text`}> Scan the bag's QR code </div>
                        <QrReader
                            delay={500}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            facingMode="environment"
                        />
                </div>
                )}
                {requestImage !== null && (
                    <div className={`${base}__qr-reader`} style={{background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${requestImage})`}}>
                        <div className={`${base}__request-image`}>
                            <div className={`${base}__upload-status-text ${this.state.error == null ? '' : "error"}`}>
                                {this.state.error == null ? (this.state.isImageUploaded ? "UPLOADED SUCCESSFULLY": "BAG SCANNED") : "SCAN FAILED PLEASE TRY AGAIN"}
                            </div>
                            {this.state.error == null && !this.state.isImageUploaded && (
                                <div className={`${base}__scan-text uploading`}> Uploading picture </div>
                            )}
                            <img style={{objectFit: "cover"}} src={requestImage}/>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default BagScanning;

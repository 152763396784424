import React from 'react';

const base = 'insta-service';

export default function WarmerSKUCategory({ category, isCurrentStock }) {
    return (
        <div className={`${base}__warmer_sku_category`}>
            <div className={`${base}__warmer_category_name`}>{category.category}</div>
            {category.items != null &&
            category.items.length > 0 &&
            category.items.map((item) => (
                <div className={`${base}__warmer_sku_items display--flex`} style={{justifyContent: 'space-between'}}>
                    <div className='display--flex' style={{alignItems: 'center'}}> 
                        {item.oos && (
                            <p className={`${base}__oos-tag`}>
                                OOS
                            </p>
                        )}
                        <p>{item.name}</p>
                    </div>
                    <div> {item.quantity}</div>
                </div>
            ))}
        </div>
    );
}

import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading';
import {Table} from "react-bootstrap";

const base = 'inventory-adjustment';


@inject('ComponentStockStore')
@observer
class LiveInventoryComponent extends Component {
    componentDidMount = () => {
        const {
            ComponentStockStore: {
                handleGetLiveInventoryOverview,
            },
        } = this.props;

        handleGetLiveInventoryOverview();
        const SIXTY_SECONDS = 1000 * 60;
        this.interval = setInterval(() => {
            handleGetLiveInventoryOverview();
        }, SIXTY_SECONDS);
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            ComponentStockStore: { inventoryOverviewLoading, skusByCategoryForLiveInventoryComponent },
        } = this.props;
        if (inventoryOverviewLoading) {
            return <Loading />;
        }
        let rows = [];
        const INVENTORY_THRESHOLD = 3;

        if (skusByCategoryForLiveInventoryComponent) {
            for (const category of Object.keys(skusByCategoryForLiveInventoryComponent)) {
                rows.push(
                    <tr style={{backgroundColor: "rgb(246, 185, 78)", textAlign: "left"}}>
                        <td colSpan="44">
                            <h3>{category}</h3>
                        </td>
                    </tr>
                )

                if (!skusByCategoryForLiveInventoryComponent[category]) {
                    continue;
                }

                for (const item of skusByCategoryForLiveInventoryComponent[category]) {
                    let columns = [];
                    for (var i = 0; i < 10; i++) {
                        columns.push(
                            <td style={{borderLeft: "3px solid gray"}}
                            >
                                {item.component_inventories.length > i ? (item.component_inventories[i].unit_sku + (item.component_inventories[i].mandatory ? '': ' (optional)'))
                                    : '-'}
                            </td>
                        )
                        columns.push(
                            <td style={{fontSize: 'x-small'}}>
                                {item.component_inventories.length > i ? item.component_inventories[i].name : '-'}
                            </td>
                        )
                        columns.push(
                            <td className={item.component_inventories.length > i
                            && item.component_inventories[i].quantity < INVENTORY_THRESHOLD
                            && item.component_inventories[i].mandatory ? 'bg--red--important': null}
                            >
                                {item.component_inventories.length > i ? item.component_inventories[i].quantity : '-'}
                            </td>
                        )
                        columns.push(
                            <td >
                                {item.component_inventories.length > i ? item.component_inventories[i].pre_orders : '-'}
                            </td>
                        )
                    }
                    rows.push(
                            <tr>
                                <td
                                >
                                    {item.sku}
                                </td>
                                <td
                                >
                                    {item.name}
                                </td>
                                <td className={item.quantity < INVENTORY_THRESHOLD ? 'bg--red--important': null}
                                >
                                    {item.quantity}
                                </td>
                                <td
                                >
                                    {item.pre_orders}
                                </td>
                                {columns}
                            </tr>
                        )
                }
            }
        }

        return (
            <Fragment>
                <InventoryHeader title='Current Stock Dashboard (Component)' />
                <Table striped bordered className="fs--medium">
                    <thead>
                        <tr>
                            <th>
                                {`SKU`}
                            </th>
                            <th>
                                Menu Item Full Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 1
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 2
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 3
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 4
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 5
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 6
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 7
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 8
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 9
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                            <th>
                                Component 10
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                In Stock
                            </th>
                            <th>
                                Pre-orders
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
                {rows.length == 0 &&
                    <div className='ml'>This screen is disabled. This will be re-enabled before opening hours tomorrow</div>
                }
            </Fragment>
        );
    }
}   

export default withRouter(LiveInventoryComponent);

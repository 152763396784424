import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput } from '@blueprintjs/datetime';
import { Icon } from '@blueprintjs/core';
import SwitchButton from 'lyef-switch-button';
import moment from 'moment';

const base = 'secondary-header';

@inject('LiveDispatchStore', 'UserStore')
@observer
class QueueScreenHeader extends Component {
    state = {
        currentTime: null,
    };

    componentDidMount = () => {
        this.currentTime();
    };

    currentTime = () => {
        const currentTime = moment().format('LTS');
        this.setState({ currentTime });
        setTimeout(this.currentTime, 1000);
    };

    componentWillUnmount() {
        clearTimeout(this.currentTime);
    }

    render() {
        const { currentTime } = this.state;
        const { goFullscreen } = this.props;

        return (
            <div className={`${base}__wrapper bg--white`}>
                <div className={`${base} display--flex  pll prl ptm pbm`}>
                    <div
                        className={`${base}__left display--flex justify--space-between flex-align-items-center`}
                    >
                        <p className="fs--medium-medium-large m0 mlm">
                            {currentTime}
                        </p>
                    </div>
                    <div
                        className={`${base}__right display--flex justify--space-between flex-align-items-center`}
                    >
                        <button
                            className={`btn btn-borderless mls`}
                            onClick={() => goFullscreen()}
                        >
                            <Icon icon="fullscreen" iconSize={24} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default QueueScreenHeader;

import { Component } from 'react';
import parameters from '../configs/parameters';
import { cities, getCity, setCity } from '../services/cities';

class CountrySelectorComponent extends Component {
    state = { selectedCity: parameters['defaultCity'] };
    componentDidMount() {
        this.handleChangeCity(getCity());
    }

    handleChangeCity = (city) => {
        setCity(city);
        this.setState({ selectedCity: city });
    };

    render() {
        return this.props.render(
            this.state.selectedCity,
            cities,
            this.handleChangeCity
        );
    }
}

export default CountrySelectorComponent;

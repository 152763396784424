const InventoryConstants = {
    INITIAL_MORNING_SHIFT: 'initial',
    END_OF_DAY_SHIFT: 'final',
    INITIAL_CHECK_COMPLETED: 'isInitialCheckCompleted',
    FINAL_CHECK_COMPLETED: 'isFinalCheckCompleted',
    INITIAL_SHIFT_START_TIME: '00:01',
    INITIAL_SHIFT_END_TIME: '12:00',
    // As the stock check time is 9 PM to 2 AM next day
    // So using 2 final shift constants
    FINAL_SHIFT_START_TIME: '21:00',
    FINAL_SHIFT_END_TIME: '23:59',
    FINAL_SHIFT_START_TIME_NEXT_DAY: '00:00',
    FINAL_SHIFT_END_TIME_NEXT_DAY: '02:00',
    STOCK_CHECK_START_HOUR: '21',
    STOCK_CHECK_END_HOUR: '2'
}

export default InventoryConstants;
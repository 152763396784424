import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import WarmerSKUCategory from './WarmerSKUCategory';

const base = 'insta-service';

@inject('ProductionStore', 'UserStore')
@observer
class CurrentWarmerStock extends Component {

    render() {
        const {
            UserStore: {
                handleSetFullscreen,
                isCurrentPageFullScreen,
            },
            currentWarmerStocks
        } = this.props;

        const groupedByCategory = currentWarmerStocks.reduce((acc, entry) => {
            const { category } = entry;
            
            if (!acc[category]) {
                acc[category] = [];
            }
            
            acc[category].push(entry);
            
            return acc;
        }, {});

        const categories = Object.keys(groupedByCategory).map(category => ({
            category,
            items: groupedByCategory[category].sort((a, b) => b.quantity - a.quantity)
        })).sort((a, b) => b.items.length - a.items.length); 

        return (
            <div>
                <div
                    className={`${base}__card_header`}
                >
                    <div
                        className='in-warmer'
                    >
                            <div style={{ flexGrow: 1, textAlign: 'center' }}>
                                IN WARMER
                            </div>
                        <button
                            className={`${base}__fullscreen_btn`}
                            onClick={() => handleSetFullscreen(!isCurrentPageFullScreen)}
                        >
                            <img
                                className={`${base}__exit_fullscreen_icon`}
                                src={isCurrentPageFullScreen? "https://image.popcontent.link/exit-full-screen.png" : "https://image.popcontent.link/go_full_screen.png"}
                                    alt="Exit fullscreen"
                            />
                        </button>
                    </div>
                </div>
                <div 
                    className={`${
                        isCurrentPageFullScreen ? 'insta-service__card_container_full-screen' : 'insta-service__card_container'
                    }`}
                >
                    {categories &&
                    categories.length > 0 &&
                    categories.map((category) => (
                        <WarmerSKUCategory
                            category={category}
                            key={`${category.category}`}
                            isCurrentStock={false}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default CurrentWarmerStock;

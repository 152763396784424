import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';

class InstaserviceScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        const categorizedItems = scannedItem.items.reduce((acc, item) => {
            const { category } = item;
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(item);
            return acc;
          }, {});

        return (
            <div className={`${base}__scanner_box_view`}
                style={{padding: '2rem 0px'}}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className={`${base}__order_type_text`}>
                            INSTASERVICE TOPUP SCAN
                    </div>
                </div>
                <div style={{marginTop: '1rem'}}>
                    {Object.keys(categorizedItems).map((category) => (
                        <table style={{border: "1px solid white", width: '22.1rem', margin: 'auto', fontSize: '2rem', textAlign: 'left'}}>
                            <th key={category} style={{color: 'white', paddingLeft: '0.5rem'}}>
                                <td>{category}</td>
                            </th>
                            {categorizedItems[category].map((item) => (
                                <tr key={item.sku} style={{backgroundColor: "#80FF00"}}>
                                    <td style={{paddingLeft: '0.5rem'}}>{item.quantity} x {item.sku}</td>
                                </tr>
                            ))}
                        </table>
                    ))}
                </div>
            </div>
        );
    }
}

export default InstaserviceScannerView;

import {observer} from "mobx-react";
import React, {Component, Fragment} from "react";

@observer
class SalePlatformIcon extends Component {
    render() {
        const { order, isOrderDelay, isNewOrder } = this.props;
        let salePlatform = order.sale_platform;
        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        const delievery_icon =
            isOrderDelay || isNewOrder
                ? 'https://image.popcontent.link/delievery_icon_white.png'
                : 'https://image.popcontent.link/delievery_icon_black.png';
        const takeaway_icon =
            isOrderDelay || isNewOrder
                ? 'https://image.popcontent.link/takeaway_icon_white.png'
                : 'https://image.popcontent.link/takeaway_icon.svg';
        const dinein_icon =
            isOrderDelay || isNewOrder
                ? 'https://image.popcontent.link/dine_in_icon_white.png'
                : 'https://image.popcontent.link/dish-fork-and-knife+1.svg';
        const grabfood_icon = 'https://image.popcontent.link/GrabFood-WhiteBG.svg';
        const foodpanda_icon = 'https://image.popcontent.link/FoodPanda-WhiteBG.svg';
        const shopeefood_icon = 'https://image.popcontent.link/ShopeeFood-WhiteBG.svg';

        return (
            <Fragment>
                {salePlatform.includes('GrabFood') && (
                    <img
                        className="aggregator_icon"
                        src={grabfood_icon}
                        alt="grabfood icon"
                    />)}
                {salePlatform.includes('FoodPanda') && (
                    <img
                        className="aggregator_icon"
                        src={foodpanda_icon}
                        alt="foodpanda icon"
                    />)}
                {salePlatform.includes('ShopeeFood') && (
                    <img
                        className="aggregator_icon"
                        src={shopeefood_icon}
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    !order.pick_up_type ? (
                        <img
                            className="delievery_icon"
                            src={delievery_icon}
                            alt="delievery icon"
                        />
                        ) : order.pick_up_type === 'TAKE_AWAY' ? (
                        <img
                            className="take_away_icon"
                            src={takeaway_icon}
                            alt="take away icon"
                        />
                        ) : (
                        <img
                            className="dine_in_icon"
                            src={dinein_icon}
                            alt="dine-in icon"
                        />
                    ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;
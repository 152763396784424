import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';


@observer
class SalePlatformIcon extends Component {
    render() {
        const { scannedItem } = this.props;
        let salePlatform = null;
        if (scannedItem.item) {
            salePlatform = scannedItem.item.sale_platform;
        } else {
            salePlatform = scannedItem.sale_platform
        }

        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        return (
            <Fragment>
                {salePlatform.includes('GrabFood') && (
                    <img
                        className="icon"
                        src="https://image.popcontent.link/GrabFood-WhiteBG.svg"
                        alt="grabfood icon"
                    />)}
                {salePlatform.includes('FoodPanda') && (
                    <img
                        className="icon"
                        src="https://image.popcontent.link/FoodPanda-WhiteBG.svg"
                        alt="foodpanda icon"
                    />)}
                {salePlatform.includes('ShopeeFood') && (
                    <img
                        className="icon"
                        src="https://image.popcontent.link/ShopeeFood-WhiteBG.svg"
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    (scannedItem.pick_up_type === 'TAKE_AWAY' || scannedItem.order_mode == "PICK_UP") ? (
                            <img
                                className="take_away_icon"
                                src="https://image.popcontent.link/takeaway_icon.svg"
                                alt="take away icon"
                            />
                        ) : 
                        (scannedItem.pick_up_type === 'DINE_IN' || scannedItem.order_mode == "DINE_IN") ? (
                            <img
                                className="icon"
                                src={
                                    'https://image.popcontent.link/dish-fork-and-knife+1.svg'
                                }
                                alt="dine-in icon"
                            />
                        ) : (
                            <img
                                className="delivery_icon"
                                src={
                                    'https://image.popcontent.link/delivery_icon.png'
                                }
                                alt="delivery icon"
                            />
                        ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;

import React, { Fragment } from 'react';

const Modal = (props) => {
    return (
        <Fragment>
            <div
                className="modal__backdrop"
                onClick={props.onBackdropClicked}
            ></div>
            <div
                className="modal text--left"
                style={{
                    ...props.customStyles,
                    width: props.width,
                    height: props.height,
                    borderRadius: props.borderRadius,
                    padding: props.padding,
                }}
            >
                {props.children}
            </div>
        </Fragment>
    );
};

export default Modal;

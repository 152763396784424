import { observable, action } from "mobx";
import * as api from '../services/api';

class BagScanningStore {
    @observable loading = false;
    @observable error = null;
    @observable bagImage = null;
    @observable data = null;

    @action setBagImage = (image) => {
        if (image) {
            this.bagImage = String(image.split('base64,')[1]);
        }
    };

    @action handleSetData = (data) => {
        this.data = data;
    }

    @action handleScanBarcode = (data) => {
        this.loading = true;
        api.handledScannedBagSticker(data, this.bagImage)
            .then(res => {
                this.bagImage = null;
            })
            .catch(err => {
                console.log(err)
                this.error = "Invalid QR code";
                this.bagImage = null;
            })
            .finally(() => {
                this.loading = false;
            });
    }
}

const store = new BagScanningStore();
export default store;

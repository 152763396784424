import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import {Helmet} from "react-helmet";

import Loading from '../Loading';
import OrderCard from './OrderCard';

import { getFromLocalStorage } from '../../services/utils';
import InstaServiceCard from './InstaServiceCard';
import CurrentWarmerStockCard from './CurrentWarmerStockCard';
// import WarmerCard from '../warmer/WarmerCard';
import InCartOrderCard from './InCartOrderCard';

const base = 'orders-kitchen';
const ONE_SECOND = 1000;

@inject('OrdersKitchenStore', 'WarmerStore', 'UserStore')
@observer
class OrdersKitchen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullscreen: false,
        };
    }

    componentDidMount() {
        const {
            OrdersKitchenStore: {
                handleGetDineInOrders,
                handleGetDeliveryOrders,
                handleGetInstaServiceProductions,
                getInCartOrders
            },
            UserStore,
        } = this.props;
        const {
            selectedHub: { isOnDemandProduction },
            handleSetFullscreen
        } = UserStore;
        let hubCode = getFromLocalStorage('hub').value;
        handleSetFullscreen(false)

        if (isOnDemandProduction) {
            handleGetDineInOrders(true);
            handleGetDeliveryOrders(true);
            handleGetInstaServiceProductions(true);
            getInCartOrders(hubCode);
            this.interval = setInterval(() => {
                handleGetDineInOrders(false);
                handleGetDeliveryOrders(false);
                handleGetInstaServiceProductions(false);
                getInCartOrders(hubCode);
            }, 10 * ONE_SECOND); // 10 seconds
        }

        //fetching warmer items
        /*
        const {
            WarmerStore: { getWarmerLevel },
        } = this.props;
        let hubCode = getFromLocalStorage('hub').value;
        getWarmerLevel(hubCode);
        const FIFTEEN_MINUTES = 15 * 60 * 1000;
        this.interval = setInterval(() => {
            getWarmerLevel(hubCode);
        }, FIFTEEN_MINUTES);
         */
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { OrdersKitchenStore, WarmerStore, UserStore } = this.props;
        const {
            isCurrentPageFullScreen: isFullscreen,
            handleSetFullscreen,
            selectedHub: { isOnDemandProduction },
        } = UserStore;
        const {
            delieveryOrders,
            dineInOrders,
            delieveryOrdersLoading,
            dineInOrdersLoading,
            instaServiceProductions,
            currentWarmerStocks,
            inCartItems
        } = OrdersKitchenStore;
        //const { warmerItems } = WarmerStore;

        const allOrders = [...dineInOrders, ...delieveryOrders]
            .filter((order) => order.status === 'Pending' && order.production_started_at)
            .sort((a, b) => a.order_date - b.order_date);

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => handleSetFullscreen(isFullscreen)}
            >
                <Helmet>
                    <title>Cooking | Outlet OS | Pop Meals</title>
                </Helmet>
                {isOnDemandProduction && (
                    <div className={`${base} width--100`}>
                        {dineInOrdersLoading || delieveryOrdersLoading ? (
                            <Loading />
                        ) : (
                            <div
                                className={`${base}__orders ${
                                    isFullscreen && 'full-screen'
                                }`}
                            >

                                {/*warmerItems && warmerItems.length > 0 && (<WarmerCard items={warmerItems} />)*/}
                                
                                <div className={`${base}__warmer_stock_container`}>
                                    <CurrentWarmerStockCard
                                        currentWarmerStocks={currentWarmerStocks}
                                    />
                                    <InCartOrderCard items={inCartItems} />
                                </div>
                                {instaServiceProductions &&
                                instaServiceProductions.length > 0 &&
                                instaServiceProductions.map((production) => (
                                    <InstaServiceCard
                                        production={production}
                                        key={production.id}
                                    />
                                ))}
                                {allOrders &&
                                    allOrders.length > 0 &&
                                    allOrders.map((order) => (
                                        <OrderCard
                                            order={order}
                                            key={order.order_number}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>
                )}
            </Fullscreen>
        );
    }
}

export default OrdersKitchen;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Fragment } from 'react';
import Modal from '../Modal';
import ReactLoading from 'react-loading';

const base = 'cashier-pickup-order-card';

@inject('DispatchStore', 'LiveDispatchStore', 'ProductionStore', 'UserStore')
@observer
class CashierPickupOrderCards extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        showConfirmationModal: false,
        loading: false,
    };

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
        if (duration >= FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { dineInOrder } = this.props;
        if (dineInOrder.order_date !== prevProps.dineInOrder.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };

    countdown = () => {
        const { dineInOrder } = this.props;
        const now = moment(dineInOrder.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (
            parseInt(minutes) > 7 &&
            parseInt(seconds) > 0 &&
            !this.state.delay
        ) {
            this.setState({ hours, minutes, seconds, delay: true });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    handleClickComplete = () => {
        const { dineInOrder, LiveDispatchStore } = this.props;
        this.setState({
            loading: true,
        });
        if (
            dineInOrder.invoice_no &&
            dineInOrder.invoice_no === 'terminal' &&
            dineInOrder.transaction_status === 'Pending'
        ) {
            this.setState({ showConfirmationModal: true });
        } else {
            LiveDispatchStore.handleConfirmDineInOrder(dineInOrder.delivery_id);
        }
    };

    handleCloseConfirmationModal = () => {
        this.setState({ loading: false });
        this.setState({ showConfirmationModal: false });
    };

    handleConfirmationSubmit = () => {
        this.setState({ loading: true });
        const { dineInOrder, LiveDispatchStore } = this.props;
        LiveDispatchStore.handleConfirmDineInOrder(dineInOrder.delivery_id);
        this.setState({ showConfirmationModal: false });
    };

    render() {
        const { showConfirmationModal, hours, minutes, seconds, delay } =
            this.state;
        const { dineInOrder } = this.props;
        return (
            <Fragment>
                <div
                    style={{ padding: '16px 24px' }}
                    className={`${base} bg--white text--left  justify--space-between`}
                    onClick={this.handleClickComplete}
                >
                    {this.state.loading ? (
                        <ReactLoading
                            type="spin"
                            color="black"
                            height={'10%'}
                            width={'10%'}
                            className="loader"
                        />
                    ) : (
                        <Fragment>
                            <div className="display--flex fs--small-medium justify--space-between">
                                <p className="text--gray">
                                    {dineInOrder.pick_up_type == 'TAKE_AWAY'
                                        ? `BAG ${
                                              !dineInOrder.bag_number
                                                  ? dineInOrder.pick_up_number
                                                  : dineInOrder.bag_number
                                          }`
                                        : `TABLE ${dineInOrder.table_number}`}
                                </p>
                                <p className="text--gray">
                                    Order #{dineInOrder.order_number}
                                </p>
                            </div>
                            <p
                                style={{ marginTop: '16px' }}
                                className="fs--medium-large"
                            >
                                {dineInOrder.customer_name}
                            </p>
                            <p
                                style={{ marginTop: '8px' }}
                                className="fs--medium"
                            >
                                {dineInOrder.phone_number}
                            </p>

                            <div
                                className="fs--medium "
                                style={{ marginTop: '8px' }}
                            >
                                {dineInOrder.transaction_status ===
                                'Pending' ? (
                                    <p className="text--red">
                                        RM{' '}
                                        {(
                                            dineInOrder.amount_due * 1.06
                                        ).toFixed(2)}{' '}
                                        Due
                                    </p>
                                ) : (
                                    <p>RM 0.00 Due</p>
                                )}
                            </div>
                            <div
                                style={{ marginTop: '16px' }}
                                className="fs--small-medium display--flex justify--space-between"
                            >
                                {dineInOrder.pick_up_type === 'TAKE_AWAY' ? (
                                    <span className="text--uppercase text--gray">
                                        Take-away
                                    </span>
                                ) : (
                                    <span className="text--uppercase text--gray">
                                        Dine-in
                                    </span>
                                )}
                                <span
                                    className={`order-time ${
                                        delay && 'text--red blinking'
                                    }`}
                                >{`${
                                    parseInt(hours) > 0 ? `${hours}:` : ``
                                }${minutes}:${seconds}`}</span>
                            </div>
                        </Fragment>
                    )}
                </div>

                {showConfirmationModal && (
                    <Modal width={'30%'}>
                        <div className={`${base}__modal`}>
                            <h3 className="mbm fs--medium-large">
                                Confirm Payment Received
                            </h3>
                            <p>Did the customer pay?</p>
                            <div className="display--flex flex-column justify--center flex-align-items-center">
                                <button
                                    className="btn btn-black width--100 ms"
                                    onClick={() =>
                                        this.handleConfirmationSubmit()
                                    }
                                >
                                    Confirm
                                </button>

                                <p
                                    className="cursor-pointer text--gray text--center width--100 ms fs--small-medium "
                                    onClick={this.handleCloseConfirmationModal}
                                >
                                    Cancel
                                </p>
                            </div>
                            <p className={`${base}__cash-reminder`}>
                                Staff will be responsible for any missing cash
                                and payments.
                            </p>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default CashierPickupOrderCards;

import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loading from '../Loading';

@inject('StockTransferStoreItems')
@observer
class InventoryReceiveStock extends Component {
    componentDidMount = () => {
        const {
            StockTransferStoreItems: { handleGetReceipts }
        } = this.props;
        handleGetReceipts();
    };

    getProductForReceipt = (receipt) => {
        const {
            StockTransferStoreItems: { handleSetReceiptId },
        } = this.props;

        handleSetReceiptId(receipt.id);
    }
    
    render() {
        const {
            StockTransferStoreItems: { receipts, loading }
        } = this.props;

        if (loading) {
            return <Loading />
        }

        return (
            <Fragment>
                <InventoryHeader title="Receive New Stock" />
                { receipts.length == 0 ?
                    <div className="mm"> 
                        No new stock to receive
                    </div>
                    : receipts.map((receipt, i) => (
                        <div className="secondary-header" key={receipt.id}>
                            <div className="btn-wrapper mm">
                                <Link to={`/inventory/receive-stock/odoo`}>
                                    <button className="btn btn-primary" onClick={(e) => this.getProductForReceipt(receipt)}>
                                        Reference: {receipt.reference_name}, From: {receipt.received_from}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </Fragment>
        );
    }
}

export default InventoryReceiveStock;

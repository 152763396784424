import React from 'react';
import InCartOrderSkuCard from './InCartOrderSkuCard';

const base = 'orders-kitchen';

export default function InCartOrderCard({ items }) {
    return (
        <div className={`${base}__in_cart_order_card`}>
            <div className={`${base}__in_car_order_card_header `}>
                <p className={`${base}__in_cart_order_name`}>iPad Order Incoming</p>
            </div>
            <div className={`${base}__in_cart_order_skus_container`}>
                {items &&
                    items.length > 0 &&
                    items.map((item) => (
                        item.item_type == "Combo" ?
                            item.combo_sub_items.map((subItem) => (
                                <InCartOrderSkuCard
                                    key={subItem.menu_item_id}
                                    item={item}
                                    subItem={subItem}
                                />
                            )) :
                                <InCartOrderSkuCard
                                    key={item.menu_item_id}
                                    item={item}
                                />
                    ))
                }
            </div>
        </div>
    );
}

import { observable, action, computed, toJS } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import moment from 'moment';
import { getFromLocalStorage } from '../services/utils';

class StockTransferStoreItems {
    @observable date = moment().format('DD-MM-YYYY');
    @observable loading = false;
    @observable hubStockTransfers = {};
    @observable currentSku = '';
    @observable itemType = 'Item';
    @observable receipts = [];
    @observable receipt_id = '';
    @observable receiptProducts = []
    @observable selectedHub = getFromLocalStorage('hub') || '';

    @computed get skuList() {
        return Object.keys(this.hubStockTransfers);
    }

    @computed get totalSkus() {
        return this.skuList.length;
    }

    @computed get totalSkusReceived() {
        return this.skuList.reduce(
            (total, sku) => (this.isSkuReceived(sku) ? total + 1 : total),
            0
        );
    }

    @computed get skusByLetter() {
        return this.skuList.reduce((acc, sku) => {
            const letter = sku.charAt(0);
            acc[letter] = acc[letter] ? acc[letter].concat([sku]) : [sku];
            return acc;
        }, {});
    }

    @computed get totalQuantity() {
        const stock_transfer_plans = this.hubStockTransfers[
            this.currentSku
        ].map((transfer) => transfer.stock_transfer_plan);
        let totalQuantity = 0;
        stock_transfer_plans.forEach((stock_transfer_plan) => {
            const {
                quantity,
                emergency_boxes_quantity,
                emergency_boxes_quantity_used,
                quality_assurance_boxes_quantity,
                quality_assurance_boxes_quantity_used,
            } = stock_transfer_plan;

            totalQuantity +=
                quantity +
                (emergency_boxes_quantity || 0) -
                (emergency_boxes_quantity_used || 0) +
                (quality_assurance_boxes_quantity || 0) -
                (quality_assurance_boxes_quantity_used || 0);
        });
        return totalQuantity;
    }

    isSkuReceived = (sku) => {
        const stock_transfer_plans = this.hubStockTransfers[sku].map(
            (transfer) => transfer.stock_transfer_plan
        );
        return stock_transfer_plans.reduce(
            (acc, cur) => acc && cur.executed,
            true
        );
    };

    @computed get initialSku() {
        for (const sku in this.hubStockTransfers) {
            if (!this.isSkuReceived(sku)) return sku;
        }
        return Object.keys(this.hubStockTransfers)[0];
    }

    @action handleSetCurrentSku = (sku) => {
        this.currentSku = sku;
    };

    @action handleSetMealType = (type) => {
        this.itemType = type === 'meals' ? 'Item' : 'Addons';
    };

    @action handleSetDate = (date) => {
        this.loading = true;
        this.date = moment(date).format('DD-MM-YYYY');
    };

    @action handleGetHubStockTransfers = () => {
        this.loading = true;
        api.getHubStockTransfers(this.date)
            .then((res) => {
                this.hubStockTransfers = res.dates[1].stock_transfer_list
                    .sort((a, b) =>
                        a.stock_transfer_plan.menu_item_sku ===
                        b.stock_transfer_plan.menu_item_sku
                            ? 0
                            : a.stock_transfer_plan.menu_item_sku >
                              b.stock_transfer_plan.menu_item_sku
                            ? 1
                            : -1
                    )
                    .reduce((acc, cur) => {
                        const { stock_transfer_plan } = cur;
                        const {
                            menu_item_sku: sku,
                            menu_item_type: itemType,
                            hub_code: hub,
                        } = stock_transfer_plan;

                        if (
                            itemType === this.itemType &&
                            hub === UserStore.selectedHub.value
                        ) {
                            // As there can be multiple stock transfer plans per sku, they are stored as array
                            if (!acc[sku]) {
                                acc[sku] = [];
                            }
                            acc[sku].push(cur);
                        }
                        return acc;
                    }, {});

                this.handleSetCurrentSku(this.initialSku);
                this.loading = false;
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleValidateTransferPlan = (transferPlan) => {
        api.validateTransferPlan(transferPlan)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.log(err))
            .finally(() => this.handleUpdateTransferPlan(transferPlan));
    };

    @action handleUpdateTransferPlan = (transferPlan) => {
        api.updateTransferPlan(transferPlan)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                UserStore.message = err.message;
            })
            .finally(() => this.handleExecuteTransferPlan(transferPlan.id));
    };

    @action handleExecuteTransferPlan = (id) => {
        api.executeTransferPlan(id)
            .then((res) => {
                this.handleGetHubStockTransfers();
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    };

    @action handleGetReceipts = () => {
        this.loading = true;
        api.getOdooReceipts(UserStore.selectedHub.value)
        .then ((res) => {
            this.receipts = res;
        })
        .catch((err) => {
            UserStore.message = err.message;
        }).finally( () => {
            this.loading = false;
        });
    }

    @action handleSetReceiptId = (receiptId) => {
        this.receipt_id = receiptId;
    }

    @action moveDuplicatesNextToFirst(receipt) {
        let productIndicesMap = new Map();
        for (let i = 0; i < receipt.length; i++) {
            const productID = receipt[i].product_id;
            if (!productIndicesMap.has(productID)) {
                productIndicesMap.set(productID, []);
            }
            productIndicesMap.get(productID).push(receipt[i]);
        }

        return Array.from(productIndicesMap.values()).reduce((acc, val) => acc.concat(val), []);
    }

    @action handleGetReceiptProducts = () => {
        this.loading = true;
        if(this.receipt_id == ''){
            this.loading = false;
            return
        }
        api.getOdooReceiptProducts(this.receipt_id)
        .then ((res) => {
            this.receiptProducts = this.moveDuplicatesNextToFirst(res);
            this.receiptProducts.forEach((receiptProduct) => {
                receiptProduct.done_value = receiptProduct.warehouse_quantity;
            })
        })
        .catch((err) => {
            UserStore.message = err.message;
        }).finally( () => {
            this.loading = false;
        });
    }

    errorCallback = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    UserStore.message = body.message;
                }
            });
        }
    };

    @action handleSaveDoneValue = () => {
        this.loading = true;
        let requests = [];
        for(var i = 0 ; i < this.receiptProducts.length; i++) {
            let request = {};
            request.id = this.receiptProducts[i].id;
            request.origin_move_ids = this.receiptProducts[i].origin_move_ids;
            request.product_id = this.receiptProducts[i].product_id;
            request.state = this.receiptProducts[i].state;
            request.partner_id = this.receiptProducts[i].partner_id;
            if(this.receiptProducts[i].done_value >= 0){
                request.quantity = this.receiptProducts[i].done_value
            } else {
                request.quantity = 0;
            }
            request.product_packaging_id = this.receiptProducts[i].product_packaging_id;
            request.demand = this.receiptProducts[i].demand
            requests.push(request);
        }
        api.updateOdooReceiptProducts(requests, this.receipt_id, UserStore.selectedHub.value, this.errorCallback)
        .then(() => {
            window.location.reload()
        }).catch((err) => {
            if (err.message) {
                UserStore.setMessage(err.message)
            } else {
                window.location.reload()
            }
            this.loading = false;
        });
    }
}

const store = new StockTransferStoreItems();
export default store;

import React, { Component } from 'react';
import { observer } from 'mobx-react';

const base = 'dispatch_scan';

@observer
class SuccessfulScannerView extends Component {
    render() {
        const {
            scannedQrCode
        } = this.props;
        return (
            <div className={`${scannedQrCode.code === "CLOCK_IN" ? base + "__clock_in" : base + "__clock_out"}`}>
                <p className={`${base}_title`}> {scannedQrCode.code === "CLOCK_IN" ? "CLOCK IN" : "CLOCK OUT"}</p>
                <br/>
                    <p className={`${base}_subtitle`}>SUCCESSFUL</p>
                <br/>
                <div className={`row ${base}__qr_code_response_container`}>
                    <div className={`col-xs-6 title`}>Staff ID:</div>
                    <div className={`col-xs-6`}>{scannedQrCode.user_id}</div>
                </div>
                <div className={`row ${base}__qr_code_response_container`}>
                    <div className={`col-xs-6 title`}>Name:</div>
                    <div className={`col-xs-6`}>{scannedQrCode.user_name}</div>
                </div>

                <div className={`row ${base}__qr_code_response_container`}>
                    <div className={`col-xs-6 title`}>Time:</div>
                    <div className={`col-xs-6`}>{scannedQrCode.time}</div>
                </div>
            </div>
        );
    }
}

export default SuccessfulScannerView;
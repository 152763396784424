import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const base = 'pick-mode';
const ONE_SEC = 1 * 1000;
const DINE_IN_ORDER_PICK_TIME_SECS = 30;

@inject('PickModeStore')
@observer
class OneItem extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
    };

    componentDidMount = () => {
        this.setCountdown();
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    setCountdown() {
        this.interval = setInterval(this.countdown, 1000);
    }

    countdown = () => {
        const { delay } = this.state;
        const {
            item,
            PickModeStore: { productionList },
        } = this.props;
        const pickItemByTime = this.getProductionPickByTime(
            productionList,
            item
        );
        const pickItemByTimeInMillisecs = moment(pickItemByTime, 'x');
        //produce_at: the time at which picking should be started. We usually give 2 mins for picking
        const difference = pickItemByTimeInMillisecs - Date.now();
        let duration;
        let isStaffLate;
        if (difference > 0) {
            //staff is not running late
            duration = difference;
        } else {
            //staff is running late
            isStaffLate = true;
            duration = Date.now() - pickItemByTimeInMillisecs;
        }

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (isStaffLate && !delay) {
            this.setState({ delay: true, hours, minutes, seconds });
        } else {
            this.setState({ hours, minutes, seconds });
        }
    };

    resetCountdown() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    getProductionPickByTime(productionList, item) {
        //this function will return the time by which staff should pick the item.
        // if they don't pick the item by this time, then they're running late, item turn to red.
        const { isDineInItem, isOnDemandProduction, productionStartTime } = this.props;
        if (!isDineInItem) {
            const productionCycle = productionList.find(
                (prod) => prod.scheduled_at === item.produce_at_pretty
            );
            if (productionCycle == null) {
                // fallback so whole screen does not break because there wasn't a productionCycle
                console.warn(
                    `Falling back to 'produce_at' for ${item.produce_at_pretty}`
                );
                return moment(item.produce_at, 'x');
            }

            return (
                item.produce_at +
                productionCycle.counting_lead_time * 60 * ONE_SEC
            );
        } else if (isOnDemandProduction) {
            return productionStartTime + DINE_IN_ORDER_PICK_TIME_SECS * ONE_SEC;
        } else {
            //this is dine in item
            return item.order_date + DINE_IN_ORDER_PICK_TIME_SECS * ONE_SEC; //30secs
        }
    }

    handlePick = () => {
        const {
            PickModeStore: { changeStatusOfItem },
            item,
            isOnDemandProduction
        } = this.props;
        changeStatusOfItem(item, isOnDemandProduction);
    };

    render() {
        const { item } = this.props;
        const sku = item.sku;
        const { hours, minutes, seconds, delay } = this.state;
        let mealLetter;
        let mealNumber;
        if (item.item_type === 'Item') {
            const mealIdentifierParts = sku.split('_')[0];
            mealLetter = mealIdentifierParts.substring(0, 1);
            mealNumber = mealIdentifierParts.substring(1);
        } else {
            mealLetter = sku.substring(0, 2);
            mealNumber = sku.substring(2);
        }
        return (
            <div
                onClick={this.handlePick}
                className={`${base}__one-item ${
                    item.isPicked ? 'picked' : delay ? 'delay' : ''
                }`}
            >
                {item.isPicked ? (
                    <svg
                        width="43"
                        height="43"
                        viewBox="0 0 43 43"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.38 43C33.2541 43 42.88 33.3741 42.88 21.5C42.88 9.62588 33.2541 0 21.38 0C9.50588 0 -0.119995 9.62588 -0.119995 21.5C-0.119995 33.3741 9.50588 43 21.38 43ZM31.5267 12.0745C30.9548 12.0761 30.4064 12.3027 30.0002 12.7054H30.0031C29.9824 12.7254 29.963 12.7468 29.9451 12.7693L19.8647 25.6128L13.7868 19.5379C13.5875 19.324 13.3472 19.1525 13.0802 19.0335C12.8132 18.9145 12.5249 18.8505 12.2326 18.8454C11.9403 18.8402 11.65 18.894 11.3789 19.0035C11.1079 19.113 10.8616 19.2759 10.6549 19.4826C10.4482 19.6893 10.2853 19.9356 10.1758 20.2066C10.0663 20.4777 10.0125 20.768 10.0177 21.0603C10.0228 21.3526 10.0868 21.6409 10.2058 21.9079C10.3248 22.1749 10.4963 22.4152 10.7102 22.6145L18.3873 30.2945C18.5942 30.5015 18.8408 30.6646 19.1122 30.7741C19.3836 30.8836 19.6744 30.9373 19.967 30.9319C20.2596 30.9265 20.5482 30.8621 20.8153 30.7426C21.0825 30.6231 21.3229 30.451 21.522 30.2365L33.1088 15.753C33.5033 15.3389 33.719 14.7862 33.7093 14.2143C33.6995 13.6424 33.4651 13.0974 33.0567 12.697C32.6483 12.2966 32.0987 12.073 31.5267 12.0745Z"
                            fill="#03C875"
                        />
                    </svg>
                ) : (
                    <div>
                        <p className="time">{`${delay ? '-' : ''}${
                            hours === '--' || hours === '0' ? '' : `${hours}:`
                        }${minutes}:${seconds}`}</p>
                        <p className="sku">{`${mealLetter}${mealNumber}`}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default OneItem;

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'dispatch_scan';

@inject('DispatchStore')
@observer
class IdleScannerView extends Component {
    render() {
        const scannedItemError = this.props.scannedItemError;
        return (
            <div className={`${base}__idle`}>
                <p className={`${base}_title`}>READY</p>
                {scannedItemError.length > 0 && (
                    <p className={`${base}__scan_error`}>{scannedItemError}</p>
                )}
                <p className={`${base}_subtitle`}>SCAN ITEM TO START</p>
            </div>
        );
    }
}

export default IdleScannerView;

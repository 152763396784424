import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Icon } from '@blueprintjs/core';

import ellipsis from '../resources/images/png/menu.png';
import { cities, getCity } from '../services/cities';

const base = 'header';

@inject('UserStore', 'ProductionStore')
@observer
class Header extends Component {
    state = {
        canShowDropdown: true,
    };
    componentDidMount = () => {
        const {
            location,
            UserStore: { pages, setPage },
        } = this.props;

        pages.forEach((page) => {
            if (location.pathname.includes(page)) {
                setPage(page);
            }
        });
    };

    handleSetPage = (page) => {
        const {
            history,
            UserStore: { setPage },
        } = this.props;

        // Disable the Dropdown visibility
        this.setState({canShowDropdown: false});
        setPage(page);
        history.push(`/${page}`);
        // enable the Dropdown visibility
        setTimeout(function () {
            this.setState({canShowDropdown: true})
        }.bind(this), 500)
    };

    render() {
        const {
            UserStore: {
                selectedHub,
                pages,
                currentPage,
                logout,
                handleSetFullscreen,
            },
            match,
            location,
        } = this.props;
        const city = cities[getCity()];
        console.log({ currentPage });

        if (location.pathname.includes('inventory/')) {
            return null;
        }

        return (
            <div className={`${base}`}>
                <div className={`${base}__container display--flex`}>
                    <h3 className={`${base}__title`}>
                        {selectedHub && `${selectedHub.pickupAddress.name}`}
                    </h3>

                    <div className={`display--flex flex-align-items-center `}>
                        {(currentPage === 'orders-kitchen' || currentPage === 'live-dispatch') && (
                            <button
                                className={`${base}__fullscreen_btn`}
                                onClick={() => handleSetFullscreen()}
                            >
                                <Icon
                                    icon="fullscreen"
                                    iconSize={30}
                                    color={'white'}
                                />
                            </button>
                        )}

                        <div className={`${base}__dropdown `}>
                            {currentPage}
                            <svg fill="#fff" className="iconmoon-icon">
                                <use xlinkHref={`#icon-caret-down`} />
                            </svg>
                            {this.state.canShowDropdown && <div className={`dropdown-content pages`}>
                                {pages
                                    .filter((page) => page !== currentPage)
                                    .map((page, i) => (
                                        <a
                                            key={i}
                                            className="cursor-pointer"
                                            onClick={() =>
                                                this.handleSetPage(page)
                                            }
                                        >
                                            {page}
                                        </a>
                                    ))}
                            </div>}
                        </div>
                        <div
                            className={`${base}__dropdown signout text--white`}
                        >
                            <img
                                className={`${base}__ellipsis`}
                                src={ellipsis}
                                alt="ellipsis icon"
                            ></img>
                            <div className={`dropdown-content`}>
                                <a
                                    className="cursor-pointer"
                                    onClick={() => logout()}
                                >
                                    Sign out
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);

import React, { Component } from 'react';
import IdleScannerView from './IdleScannerView';
import BarcodeReader from 'react-barcode-reader';
import Loading from '../Loading';
import SuccessfulScannerView from "./SuccessfulScannerView";
import InvalidScannerView from "./InvalidScannerView";

const base = 'dispatch_scan';

class ScanOverview extends Component {
    handleError = (err) => {
        console.error(err);
    };

    render() {
        const {
            scannedQrCode,
            scannedQrCodeError,
            handleScan,
            loading,
        } = this.props;
        if (loading) {
            return <Loading />;
        }
        return (
            <div className={`${base} mxl`}>
                <div className={`${base}__header`}>
                    <div className={`${base}__header_left width--33 ptm plm`}>
                        Clock In/Out Scanner
                    </div>
                </div>
                <BarcodeReader onError={this.handleError} onScan={handleScan} />
                {scannedQrCode == null &&
                    scannedQrCodeError == null && (
                    <IdleScannerView scannedQrCodeError={scannedQrCodeError} />
                )}
                {scannedQrCode != null && (
                    <SuccessfulScannerView scannedQrCode={scannedQrCode} />
                )}
                {scannedQrCodeError != null && (
                    <InvalidScannerView scannedQrCodeError={scannedQrCodeError}/>
                )}
            </div>
        );
    }
}

export default ScanOverview;
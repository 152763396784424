import { action, computed, observable } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import { getFromLocalStorage, saveToLocalStorage } from '../services/utils';
import { differenceWith, isEqual } from 'lodash';
import moment from 'moment';
import ScanningStore from './common/ScanningStore';

class ProductionStore {
    ALL_OVEN_TYPES = 'ALL_OVEN_TYPES';
    PASTA_CO_BRAND_NAME = 'Pasta & Co';
    DAPOR_FAMIRA_BRAND_NAME = 'Dapor Famira';
    DAPOR_FAMIRA_BRAND_NAME_SHORT = 'D Famira';
    DAHMAKAN_BRAND_NAME = 'dahmakan';

    CYCLE_SCREEN = {
        ALL_CYCLES: 'ALL_CYCLES',
        CURRENT_CYCLES: 'CURRENT_CYCLES',
        SINGLE_CYCLE: 'SINGLE_CYCLE',
    };

    CYCLE_STATUS = {
        STARTED: 'Started',
        PICKED: 'Picked',
        COMPLETED: 'Completed',
    };

    ORDER_TYPE = {
        DINE_IN: 'DINE_IN',
        TAKE_AWAY: 'TAKE_AWAY',
    };

    ORDER_STATUS = {
        ON_ROUTE: 'ON_ROUTE',
        IN_WARMER: 'IN_WARMER',
        IN_PRODUCTION: 'IN_PRODUCTION',
    };

    @observable authedUser = null;
    @observable loading = true;
    @observable totalNumberOfLabels = 0;
    @observable productionScreen =
        getFromLocalStorage('productionScreen') ||
        this.CYCLE_SCREEN.CURRENT_CYCLES;
    @observable productionList = [];
    @observable currentProductionCycles = null;
    @observable date = moment().format('DD-MM-YYYY');
    @observable ovenTypes = [];
    @observable ovenType =
        getFromLocalStorage('ovenType') || this.ALL_OVEN_TYPES;
    @observable showLatestCycle =
        getFromLocalStorage('showLatestCycle') || false;
    @observable isAutoPrint = getFromLocalStorage('isAutoPrint') || false;
    @observable is300DPI = getFromLocalStorage('is300DPI') || false;
    @observable dineInOrders = [];
    @observable deliveryItems = [];
    @observable scannedItem = null;
    @observable scannedItemSKU = '';
    @observable scannedItemError = '';
    @observable showScannedItemOnCycles = false;
    @observable isImageUploading = false;
    @observable showImage = false;
    @observable itemImage = null;
    @observable itemScanLoading = false;
    @observable originalItemImageLink = null;
    printBuffer = [];
    printBufferDineInOrders = [];
    printBufferDeliveryOrders = [];
    productionWavesLastModified = null;
    dineInOrdersLastModified = null;
    deliveryItemsLastModified = null;
    lastOrderTimeStamp = null;
    audio = new Audio(`${process.env.PUBLIC_URL}/bell_reduced_volume.mp3`);

    @action resetProductionLastModified = () => {
        this.productionWavesLastModified = null;
    };

    @action setProductionScreen = (screen) => {
        this.productionScreen = screen;
        saveToLocalStorage('productionScreen', screen);
    };

    @action setAutoPrint = (isAutoPrint) => {
        this.isAutoPrint = isAutoPrint;
        saveToLocalStorage('isAutoPrint', isAutoPrint);
    };

    @action set300DPI = (is300DPI) => {
        this.is300DPI = is300DPI;
        saveToLocalStorage('is300DPI', is300DPI);
    };

    @action handleUploadImage = (image, item) => {
        this.isImageUploading = true;
        api.uploadItemImage(image, item)
            .then((res) => {
                this.showImage = true;
                setTimeout(() => {
                    this.showImage = false;
                }, 5000);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isImageUploading = false;
            });
    };

    @action setItemImage = (image) => {
        if (image) {
            this.itemImage = image;
        }
    };

    errorCallbackSKUScanAPI = (errorObject) => {
        if (errorObject.response) {
            errorObject.response.json().then((body) => {
                if (body.message) {
                    this.scannedItemError = body.message;
                }
            });
        }
    };

    @action handledScannedItem = (
        todayMenuId,
        incrementalNumber,
        pickUpNumber = null
    ) => {
        this.showScannedItemOnCycles = true;
        this.itemScanLoading = true;

        api.handledScannedItem(
            todayMenuId,
            this.date,
            incrementalNumber,
            pickUpNumber,
            this.errorCallbackSKUScanAPI
        )
            .then((res) => {
                this.scannedItem = res;
                this.scannedItemSKU = res.item.sku;
                this.scannedItemError = '';
                this.originalItemImageLink = res.dispatch_scan_example_image;
                if (this.itemImage) {
                    const imageToBeUploaded = String(
                        this.itemImage.split('base64,')[1]
                    ); //removing the pre-string from the image
                    this.handleUploadImage(
                        imageToBeUploaded,
                        res.item
                    );
                }
                if (res.sound_file) {
                    const audio = new Audio(
                        `${process.env.PUBLIC_URL}/${res.sound_file}`
                    );
                    audio.play()
                        .catch(error => {
                            console.log("Error playing sound alert");
                        });
                }
            })
            .catch((err) => {
                this.scannedItem = null;
                console.log(err);
            })
            .finally(() => {
                this.itemScanLoading = false;

                setTimeout(() => {
                    this.showScannedItemOnCycles = false;
                }, 5 * 1000);
            });
    };

    @action handleGetDineInItems = (forceLatest = false, date = this.date) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.dineInOrdersLastModified;
        }
        api.getDineInItems(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.dineInOrdersLastModified = lastModified;

                    const newOrders = differenceWith(
                        responseBody.list,
                        this.dineInOrders,
                        isEqual
                    );

                    if (newOrders.length && this.dineInOrders) {
                        const audio = new Audio(
                            `${process.env.PUBLIC_URL}/bell_reduced_volume.mp3`
                        );
                        audio.play();
                    }

                    this.dineInOrders = responseBody.list || [];
                }
            })
            .then(() => {
                this.loading = false;
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            });
    };

    @action handleGetDeliveryItems = (
        forceLatest = false,
        date = this.date
    ) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.deliveryItemsLastModified;
        }
        api.getDeliveryItems(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.deliveryItemsLastModified = lastModified;

                    const newItems = differenceWith(
                        responseBody.list,
                        this.deliveryItems,
                        function (a, b) {
                            return (
                                a.today_menu_id === b.today_menu_id &&
                                a.order_number === b.order_number &&
                                a.incremental_number === b.incremental_number
                            );
                        }
                    );

                    if (newItems.length && this.deliveryItems) {
                        const audio = new Audio(
                            `${process.env.PUBLIC_URL}/bell_reduced_volume.mp3`
                        );
                        audio.play();
                    }

                    this.deliveryItems = responseBody.list || [];
                }
            })
            .then(() => {
                this.loading = false;
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            });
    };

    @action recordPickingData = (
        hub,
        cycleDate,
        cycle,
        mealsCount,
        startTime,
        endTime
    ) => {
        try {
            api.updatePickingData(
                hub,
                cycleDate,
                cycle,
                mealsCount,
                startTime,
                endTime
            );
        } catch (err) {
            UserStore.message = err.message;
        }
    };

    // functions to print
    writeToSelectedPrinter(
        isAutomaticPrinting,
        dataToWriteLocalAndCloudPrinters,
        printSuccessCallback,
        printErrorCallback
    ) {
        ScanningStore.printToLocalOrCloudPrinters(
            isAutomaticPrinting,
            true,
            dataToWriteLocalAndCloudPrinters,
            printSuccessCallback,
            printErrorCallback ? printErrorCallback : this.errorCallback
        );
    }

    errorCallback() {
        alert(
            'Printer not connected. Check printer settings and/or refresh the page'
        );
    }

    @action sendPrintRequest = (
        request,
        printSuccessCallback,
        printErrorCallback
    ) => {
        this.loading = true;
        api.sendPrintRequest(request)
            .then((res) => {
                if (printSuccessCallback) printSuccessCallback(res);
            })
            .catch((err) => {
                if (printErrorCallback) printErrorCallback(err);
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action sendInstaServicePrintRequest = (
        request,
        printSuccessCallback,
        printErrorCallback
    ) => {
        this.loading = true;
        api.sendPrintRequest(request)
            .then((res) => {
                if (printSuccessCallback) printSuccessCallback(res);
            })
            .catch((err) => {
                if (printErrorCallback) printErrorCallback(err);
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action playAudio = () => {
        this.audio.play();
    }

}

const store = new ProductionStore();
export default store;

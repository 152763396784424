import React, { Component } from 'react';
import { observer } from 'mobx-react';

const base = 'dispatch_scan';

@observer
class IdleScannerView extends Component {
    render() {
        const {scannedQrCodeError} = this.props;
        const isScanRepeatedError = scannedQrCodeError.indexOf("done!") > -1;
        return (
            <div className={`${base}__invalid_qr`}>
                <p className={`${base}_title text--white`}>{isScanRepeatedError ? "SCAN REPEATED" : "ERROR"}</p>
                <br/>
                <p className={`${base}_subtitle text--white`}>{isScanRepeatedError ? "YOU HAVE ALREADY SCANNED. NO MORE SCANNING NEEDED"
                    : scannedQrCodeError}</p>
            </div>
        );
    }
}

export default IdleScannerView;
import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';

const base = 'live-dispatch';

const STICKER_TYPES = {
    ITEM_QR: 'item_qr',
    BAG_QR: 'bag_qr',
    WARMER_QR: 'warmer_qr'
};

@inject('LiveDispatchStore', 'ProductionStore', 'UserStore')
@observer
class PrintStickersModal extends Component {
    state = {
        isPrintError: false,
        buttonClicked: false,
    };

    handlePrintErrorCallback = () => {
        this.setState({ isPrintError: true });
    };

    handlePrintSuccessCallback = () => {
        const { handleModalState, sendToastNotification, handlePrintBtnState } =
            this.props;
        const { buttonClicked } = this.state;
        handleModalState(false);
        if (sendToastNotification) {
            sendToastNotification();
        }
        if (buttonClicked) {
            if (buttonClicked === 'WARMER_QR') {
                handlePrintBtnState('isPrintWarmerQRButtonDisabled', true, true);
            } else if (buttonClicked === 'ITEM_QR') {
                handlePrintBtnState('isPrintItemQRButtonDisabled', true, true);
            } else {
                handlePrintBtnState('isPrintBagQRButtonDisabled', true, true);
            }
        }
    };

    handleDismissBtnClick = () => {
        const { handleModalState } = this.props;
        this.setState({ isPrintError: false });
        handleModalState(false);
    };

    handlePrintBtnClick = ({ stickerType }) => {
        const {
            orderOrBag,
            ProductionStore,
            UserStore: { selectedHub }
        } = this.props;
        let print_mode = null;
        let printer_id = null;
        const order_number = orderOrBag.order_number;
        if (stickerType === STICKER_TYPES.WARMER_QR) {
            print_mode = 'WARMER_STICKER';
            printer_id = selectedHub.kitchenPrinterId;
            this.setState({ buttonClicked: 'WARMER_QR' });
        } else if (stickerType === STICKER_TYPES.ITEM_QR) {
            print_mode = 'ITEM_STICKER';
            printer_id = selectedHub.kitchenPrinterId;
            this.setState({ buttonClicked: 'ITEM_QR' });
        } else {
            //bag sticker
            print_mode = 'BAG_STICKER';
            printer_id = selectedHub.dispatchPrinterId;
            this.setState({ buttonClicked: 'BAG_QR' });
        }
        ProductionStore.sendPrintRequest({order_number, print_mode, printer_id},
            this.handlePrintSuccessCallback,
            this.handlePrintErrorCallback);
    };

    render() {
        const {
            orderOrBag,
            handleModalState,
            isPrintItemQRButtonDisabled,
            isPrintBagQRButtonDisabled,
            isPrintWarmerQRButtonDisabled,
            UserStore: { selectedHub },
            isWarmerItem
        } = this.props;
        const { isPrintError } = this.state;

        //only show Item QR btn if it's takeaway order or it's delivery order and onDP is enabled for outlet
        const showItemQRBtn = !isWarmerItem &&
            ((orderOrBag.pick_up_type &&
                orderOrBag.pick_up_type === 'TAKE_AWAY') ||
            (!orderOrBag.pick_up_type && selectedHub.isOnDemandProduction));

        return (
            <div className={`${base}__modal`} style={{}}>
                {isPrintError ? (
                    <div className={`${base}__printer_fail`}>
                        <h3 className={`print_text`}>Print Failed</h3>
                        <p className="no_printer">No printer detected</p>
                        <button
                            className={`${base}__dismiss_button`}
                            onClick={this.handleDismissBtnClick}
                        >
                            Dismiss
                        </button>
                    </div>
                ) : (
                    <Fragment>
                        <h3 className={`${base}__print_text`}>Print Sticker</h3>
                        {isWarmerItem ? (
                            <div className={`${base}__modal_order`}>
                                <p className="order_type_text">INSTASERVICE</p>
                                <button
                                    className={`${base}__print_bag_qr_btn`}
                                    onClick={() =>
                                        this.handlePrintBtnClick({
                                            stickerType: STICKER_TYPES.WARMER_QR,
                                        })
                                    }
                                    disabled={isPrintWarmerQRButtonDisabled}
                                >
                                    QR
                                </button>
                            </div>
                        ) : orderOrBag.bag_number ||
                        (orderOrBag.pick_up_type &&
                            orderOrBag.pick_up_type === 'TAKE_AWAY') ? (
                            <div className={`${base}__modal_order`}>
                                <p className="order_type_text">
                                    Pickup/Takeaway
                                </p>

                                <button
                                    className={`${base}__print_bag_qr_btn`}
                                    onClick={() =>
                                        this.handlePrintBtnClick({
                                            stickerType: STICKER_TYPES.BAG_QR,
                                        })
                                    }
                                    disabled={isPrintBagQRButtonDisabled}
                                >
                                    Bag QR
                                </button>
                            </div>
                        ) : (
                            <div className={`${base}__modal_order`}>
                                <p className="order_type_text">Dine-in</p>
                                <button
                                    className={`${base}__print_bag_qr_btn`}
                                    onClick={() =>
                                        this.handlePrintBtnClick({
                                            stickerType: STICKER_TYPES.ITEM_QR,
                                        })
                                    }
                                    disabled={isPrintItemQRButtonDisabled}
                                >
                                    Item QR
                                </button>
                            </div>
                        )}

                        <button
                            className={`${base}__cancel_button`}
                            onClick={() => handleModalState(false)}
                        >
                            Cancel
                        </button>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default PrintStickersModal;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { getFromLocalStorage } from '../../services/utils';

@inject('WarmerStore')
@observer
class Warmer extends Component {
    state = {
        currentTime: null,
    };

    currentTime = () => {
        const currentTime = moment().format('LTS');
        this.setState({ currentTime });
        setTimeout(this.currentTime, 1000);
    };

    componentDidMount() {
        this.currentTime();
        const {
            WarmerStore: { getWarmerLevel },
        } = this.props;
        let hubCode = getFromLocalStorage('hub').value;
        getWarmerLevel(hubCode);
        const FIFTEEN_MINUTES = 15 * 60 * 1000;
        this.interval = setInterval(() => {
            getWarmerLevel(hubCode);
        }, FIFTEEN_MINUTES);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    isNearBusinessEnd = () => {
        const endTime = moment().set({'hour': 20, 'minute': 0, 'second': 0, 'millisecond': 0});
        return moment().isAfter(endTime.subtract(1, 'hours'))
    }

    render() {
        const { currentTime } = this.state;
        const { WarmerStore } = this.props;
        const { warmerItems } = WarmerStore;
        return (
            <React.Fragment>
                <p className="fs--large">{currentTime}</p>
                <div className="performance__card rectangle row mtl mbxl m0">
                    <span>
                        <h2 className=" fs--large">
                            Pastikan tahap Warmer adalah seperti yang dipamerkan
                            di kolumn kuantiti setiap masa.
                            <br />
                            Apabila makanan dibeli, hasilkan yang lain untuk
                            dimasukkan ke dalam Warmer
                        </h2>
                    </span>
                </div>
                { this.isNearBusinessEnd() && (
                <div className="performance__card rectangle row mtl mbxl m0">
                    <span>
                        <h2 className=" fs--large">
                            Habiskan kesemua stok yang sedia ada sebelum menyediakan stok baharu
                        </h2>
                    </span>
                </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th className=" fs--largest">SKU</th>
                            <th className=" fs--largest">Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {warmerItems &&
                            warmerItems.map((item) => (
                                <tr key={item.menu_item_id}>
                                    <td
                                        className=" fs--largest"
                                        style={{
                                            backgroundColor: `${
                                                item.sku.indexOf('A') > -1
                                                    ? '#ff642e'
                                                    : '#fcab3f'
                                            }`,
                                            color: 'white',
                                        }}
                                    >
                                        {item.sku}
                                    </td>
                                    <td className=" fs--largest">
                                        x{item.reorder_quantity}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default Warmer;

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';


@observer
class SalePlatformIcon extends Component {
    render() {
        const { order } = this.props;
        let salePlatform = order.sale_platform;
        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        return (
            <Fragment>
                {salePlatform.includes('GrabFood') && (
                    <img
                        className="take_away_icon"
                        src="https://image.popcontent.link/GrabFood-WhiteBG.svg"
                        alt="grabfood icon"
                    />)}
                {salePlatform.includes('FoodPanda') && (
                    <img
                        className="take_away_icon"
                        src="https://image.popcontent.link/FoodPanda-WhiteBG.svg"
                        alt="foodpanda icon"
                    />)}
                {salePlatform.includes('ShopeeFood') && (
                    <img
                        className="take_away_icon"
                        src="https://image.popcontent.link/ShopeeFood-WhiteBG.svg"
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    (order.pick_up_type === 'TAKE_AWAY' || order.customer_self_pickup_at_outlet) ? (
                            <img
                                className="take_away_icon"
                                src="https://image.popcontent.link/takeaway_icon.svg"
                                alt="take away icon"
                                style={{filter:"invert(1)"}}
                            />
                        ) : 
                        order.pick_up_type === 'DINE_IN' ? (
                            <img
                                className="dine_in_icon"
                                src={
                                    'https://image.popcontent.link/dish-fork-and-knife+1.svg'
                                }
                                alt="dine-in icon"
                                style={{filter:"invert(1)"}}
                            />
                        ) : (
                            <img
                                className="delivery_icon"
                                src={
                                    'https://image.popcontent.link/delievery_icon_white.png'
                                }
                                alt="delivery icon"
                            />
                        ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;

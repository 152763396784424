import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';

const base = 'secondary-header';

@inject('HubPerformanceStore')
@observer
class HubPerformanceHeader extends Component {
    render() {
        const { HubPerformanceStore } = this.props;
        const { date, handleGetDispatches } = HubPerformanceStore;

        return (
            <div className={`${base} bg--white`}>
                <div className="row pll prl ptm pbm">
                    <div
                        className={`${base}__left col-xs-12 col-md-4 flex-1-only text--left`}
                    >
                        <DateInput
                            formatDate={(date) =>
                                moment(date).date() === moment().date()
                                    ? moment(date).format(
                                          '[Today •] DD/MM/YYYY'
                                      )
                                    : moment(date).format('DD/MM/YYYY')
                            }
                            onChange={(date) =>
                                handleGetDispatches(
                                    moment(date).format('DD-MM-YYYY')
                                )
                            }
                            parseDate={(str) =>
                                moment(str, 'DD/MM/YYYY').toDate()
                            }
                            placeholder="Pick a date"
                            value={moment(date, 'DD/MM/YYYY').toDate()}
                            inputProps={{ leftIcon: 'calendar' }}
                        />
                    </div>
                    <h1 className={`col-xs-12 col-md-4 m0`}>
                        On-time Performance
                    </h1>
                </div>
            </div>
        );
    }
}

export default HubPerformanceHeader;

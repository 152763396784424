import React, { Component } from 'react';
import { observer } from 'mobx-react';

const base = 'dispatch_scan';

@observer
class IdleScannerView extends Component {
    render() {
        return (
            <div className={`${base}__idle`}>
                <p className={`${base}_title`}>READY</p>
                <br/>
                <p className={`${base}_subtitle`}>Open Rider app</p>
                <br/>
                <p className={`${base}_subtitle`}>Tap on Clock in/out for outlet partner's button</p>
                <br/>
                <p className={`${base}_subtitle`}>Scan QR Code</p>
            </div>
        );
    }
}

export default IdleScannerView;
import React, { Component } from 'react';
import BagImageSection from './BagImageSection';
import SalePlatformIcon from './SalePlatformIcon';
import SKUItem from './SKUItem';

const base = 'dispatch_scan';

class BagScannerLiveDispatchView extends Component {
    render() {
        const {
            scannedItem,
            itemImage: itemWebCamImage,
        } = this.props;

        const customerName = scannedItem.customer_name;

        const customerNameToShow = customerName ? 
                                    (customerName.length > 13
                                        ? customerName.slice(0, 10) + '...'
                                        : customerName) : '';
        const referenceNumber = scannedItem.pick_up_number ? scannedItem.pick_up_number : scannedItem.order_id;
        let userInfoToShow = customerNameToShow;
        let scannedInfoToShow = scannedItem.short_order_id;
        if (!scannedInfoToShow) {
            scannedInfoToShow = scannedItem.order_mode == "PICK_UP" ? "Take-away" : 
                                scannedItem.order_mode == "DINE_IN" ? "Dine-In" : "Delivery";
        }

        let meals = null;
        let cutlery = null;
        let addons = null;
        let drinks = null;
        if (scannedItem.items) {
            meals = scannedItem.items.filter((item) => item.item_type === 'Item' && item.sku != 'Cutlery');
            cutlery = scannedItem.items.filter((item) => item.item_type === 'Item' && item.sku == 'Cutlery');
            addons = scannedItem.items.filter((item) => item.item_type === 'Addons' && item.category_name != 'Drinks');
            drinks = scannedItem.items.filter((item) => item.item_type === 'Addons' && item.category_name == 'Drinks');
        }

        return (
            <div className={`${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <SalePlatformIcon 
                        scannedItem = {scannedItem} />
                        {(!scannedItem.short_order_id && scannedItem.order_mode != "DINE_IN") && (
                            <span className= {scannedItem.order_mode == "PICK_UP" ? "take_away_bag_scan_icon_text" : "delivery_bag_scan_icon_text"}>{referenceNumber}</span>
                        )}
                    </div>
                    <div className="order_info">
                        <p className={`${base}__bag_scanned_info`}
                         style={customerNameToShow ? {} : {fontSize: '3rem', fontWeight: '700', marginTop: 0}}>
                            {scannedInfoToShow}
                        </p>
                        {customerNameToShow && (
                            <p className={`${base}__bag_user_info`}>
                                {userInfoToShow}
                            </p>
                        )}
                    </div>
                </div>
                <BagImageSection
                    itemWebCamImage={itemWebCamImage}
                />
                {scannedItem.items && (
                    <div className={`${base}__sku_items_container`}>
                        <div className={`${base}__sku_items_header`}>
                            ITEMS
                        </div>
                        {meals &&
                            meals.length > 0 &&
                            meals.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'meals'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == meals.length - 1}
                                    isLastCategory={addons.length == 0 && drinks.length == 0 && cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {addons &&
                            addons.length > 0 &&
                            addons.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'addons'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == addons.length - 1}
                                    isLastCategory={drinks.length == 0 && cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {drinks &&
                            drinks.length > 0 &&
                            drinks.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'drinks'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == drinks.length - 1}
                                    isLastCategory={cutlery.length == 0}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                        {cutlery &&
                            cutlery.length > 0 &&
                            cutlery.map((item, index) => (
                                <SKUItem
                                    item={item}
                                    type={'cutlery'}
                                    isFirstItem={index == 0}
                                    isLastItem={index == cutlery.length - 1}
                                    isLastCategory={true}
                                    key={`${item.sku}_${item.incremental_number}`}
                                />
                            ))}
                    </div>

                )}
            </div>
        );
    }
}

export default BagScannerLiveDispatchView;

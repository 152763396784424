import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

class Inventory extends Component {
    render() {
        return (
            <div className="inventory-menu">
                <Helmet>
                    <title>Inventory</title>
                </Helmet>
                <h1>Inventory Tool</h1>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/live/adjustment">
                        <button className="btn btn-primary width--300px">
                            Current Stock
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/adjustment">
                        <button className="btn btn-primary width--300px">
                            Quick Stock Update
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/stock-count">
                        <button className="btn btn-primary width--300px">
                            Stock Count
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/receive-stock">
                        <button className="btn btn-primary width--300px">
                            Receive New Stock
                        </button>
                    </Link>
                </div>
                <div className="btn-wrapper mm">
                    <Link to="/inventory/add-component-stock">
                        <button className="btn btn-primary width--300px">
                            Add Component Stock
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default Inventory;

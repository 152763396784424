import React, { Component } from 'react';
import ScanOverview from './ScanOverview';
import Fullscreen from 'react-full-screen';
import { inject, observer } from 'mobx-react';
import AttendanceScanHeader from "./AttendanceScanHeader";

@inject('AttendanceStore', 'ScanningStore')
@observer
class AttendanceScan extends Component {
    state = {
        isFullscreen: false,
    };

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    handleScanWrapper = (scannedQrCode) => {
        const {AttendanceStore } = this.props;
        const { handledScannedAttendance } = AttendanceStore;
        handledScannedAttendance(scannedQrCode);
    };

    render() {
        const { isFullscreen } = this.state;
        const { AttendanceStore } = this.props;

        const {
            scannedQrCode,
            scannerLoading,
            scannedQrCodeError,
        } = AttendanceStore;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <AttendanceScanHeader goFullscreen={this.handleSetFullscreen} />
                <ScanOverview
                    scannedQrCode={scannedQrCode}
                    scannedQrCodeError={scannedQrCodeError}
                    handleScan={this.handleScanWrapper}
                    loading={scannerLoading}
                />
            </Fullscreen>
        );
    }
}

export default AttendanceScan;
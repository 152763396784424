import React, { Component } from 'react';
import Fullscreen from 'react-full-screen';
import { inject, observer } from 'mobx-react';

import PickModeHeader from './PickModeHeader';
import Loading from '../Loading';
import OneItem from './OneItem';

const base = 'pick-mode';

@inject('PickModeStore', 'UserStore')
@observer
class PickMode extends Component {
    state = {
        isFullscreen: false,
    };

    componentDidMount() {
        const {
            PickModeStore: { handleGetDineInOrders, handleGetDeliveryOrders },
        } = this.props;

        handleGetDineInOrders();
        handleGetDeliveryOrders();
        this.interval = setInterval(() => {
            handleGetDineInOrders(false);
            handleGetDeliveryOrders(false);
        }, 1000 * 5); // every 5 seconds
    }

    componentWillUnmount() {
        const {
            PickModeStore: { resetProductionLastModified },
        } = this.props;
        clearInterval(this.interval);
        resetProductionLastModified();
    }

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    render() {
        const { isFullscreen } = this.state;
        const { PickModeStore, UserStore } = this.props;
        const { loading, allItemsInAllMeals } = PickModeStore;
        const { pendingDineInOrders, pendingDeliveryOrders } = PickModeStore;
        const { selectedHub } = UserStore;
        const isOnDemandProduction = selectedHub.isOnDemandProduction;
        if (loading) {
            return <Loading />;
        }
        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <PickModeHeader goFullscreen={this.handleSetFullscreen} />
                <div className={`${base}`}>
                    <div className={`${base}__main`}>
                        {!isOnDemandProduction && (
                            <div
                                className={`${base}__one-screen ${base}__cycles`}
                            >
                                <h3 className="title">Production</h3>
                                <div className={`${base}__meal-types`}>
                                    {allItemsInAllMeals &&
                                        allItemsInAllMeals.length > 0 &&
                                        allItemsInAllMeals.map(
                                            (allItemsInOneMeal) => (
                                                <div
                                                    className={`${base}__meal-card`}
                                                    key={
                                                        allItemsInOneMeal.mealSKU
                                                    }
                                                >
                                                    {allItemsInOneMeal &&
                                                        allItemsInOneMeal.data &&
                                                        allItemsInOneMeal.data
                                                            .length > 0 && (
                                                            <div
                                                                className={`${base}__meal-type`}
                                                            >
                                                                {allItemsInOneMeal.data.map(
                                                                    (
                                                                        singularItem
                                                                    ) => (
                                                                        <OneItem
                                                                            item={
                                                                                singularItem
                                                                            }
                                                                            key={`${allItemsInOneMeal.mealSKU}_${singularItem.sku}_${singularItem.wave_id}_${singularItem.index}`}
                                                                        />
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        )}
                        {isOnDemandProduction && (
                            <div className={`${base}__one-screen delivery`}>
                                <h3 className="title">Delivery</h3>
                                <div className={`${base}__meal-types`}>
                                    {pendingDeliveryOrders &&
                                        pendingDeliveryOrders.length > 0 &&
                                        pendingDeliveryOrders.map(
                                            (deliveryOrder) => (
                                                <div
                                                    className={`${base}__meal-card`}
                                                    key={
                                                        deliveryOrder.order_number
                                                    }
                                                >
                                                    <h4 className="customer-name-order-num">{`${deliveryOrder.order_number}`}</h4>
                                                    <div
                                                        className={`${base}__meal-type`}
                                                    >
                                                        {deliveryOrder.items.map(
                                                            (singularItem) => (
                                                                <OneItem
                                                                    productionStartTime={
                                                                        deliveryOrder.order_date
                                                                    }
                                                                    isOnDemandProduction={
                                                                        isOnDemandProduction
                                                                    }
                                                                    item={
                                                                        singularItem
                                                                    }
                                                                    isDineInItem={
                                                                        true
                                                                    }
                                                                    key={`${deliveryOrder.order_number}_${singularItem.sku}_${singularItem.incremental_number}`}
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        )}
                        <div className={`${base}__one-screen dine-in`}>
                            <h3 className="title">Dine in</h3>
                            <div className={`${base}__meal-types`}>
                                {pendingDineInOrders &&
                                    pendingDineInOrders.length > 0 &&
                                    pendingDineInOrders.map((dineInOrder) => (
                                        <div
                                            className={`${base}__meal-card`}
                                            key={dineInOrder.order_number}
                                        >
                                            <h4 className="customer-name-order-num">{`${
                                                dineInOrder.pick_up_number
                                            } - ${
                                                dineInOrder.customer_name
                                                    ? dineInOrder.customer_name
                                                    : dineInOrder.order_number
                                            }`}</h4>
                                            <div
                                                className={`${base}__meal-type`}
                                            >
                                                {dineInOrder.items.map(
                                                    (singularItem) => (
                                                        <OneItem
                                                            item={singularItem}
                                                            isDineInItem={true}
                                                            key={`${dineInOrder.order_number}_${singularItem.sku}_${singularItem.incremental_number}`}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Fullscreen>
        );
    }
}

export default PickMode;

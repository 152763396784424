import React, { Component } from 'react';
import DispatchScanHeader from './DispatchScanHeader';
import ScanOverview from './ScanOverview';
import Fullscreen from 'react-full-screen';
import { inject, observer } from 'mobx-react';

@inject('DispatchStore', 'ScanningStore')
@observer
class DispatchScan extends Component {
    state = {
        isFullscreen: false,
        show: false
    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    handleSetFullscreen = () => {
        this.setState((prevState) => ({
            isFullscreen: !prevState.isFullscreen,
        }));
    };

    handleScanWrapper = (scannedBarCode) => {
        const { ScanningStore, DispatchStore } = this.props;
        const { handleScan } = ScanningStore;
        const { handledScannedItem, handledScannedWarmerItem } = DispatchStore;
        handleScan(scannedBarCode, handledScannedItem, handledScannedWarmerItem);
        this.setState({show : true})
    };

    handleKeyDown = () => {
        this.setState({show : false})
    }

    handleClick =(event) => {
        if (event.target.className.indexOf("dispatch_scan") == -1) {
            this.setState({show : false})
        }

        if (event.target.className.indexOf("dispatch_scan") > -1) {
            this.setState({show : true})
        }
    }

    render() {
        const { isFullscreen } = this.state;
        const { DispatchStore } = this.props;

        const {
            scannedItem,
            scannedItemSKU,
            scannedItemError,
            scannerLoading,
        } = DispatchStore;

        return (
            <Fullscreen
                enabled={isFullscreen}
                onChange={(isFullscreen) => this.setState({ isFullscreen })}
            >
                <div onClick={this.handleClick}>
                    <DispatchScanHeader goFullscreen={this.handleSetFullscreen} />
                    <ScanOverview
                        show={this.state.show}
                        scannedItem={scannedItem}
                        scannedItemSKU={scannedItemSKU}
                        scannedItemError={scannedItemError}
                        handleScan={this.handleScanWrapper}
                        loading={scannerLoading}
                    />
                </div>
            </Fullscreen>
        );
    }
}

export default DispatchScan;

import React from 'react';

const base = 'live-dispatch';

export default function CustomToastContainer() {
    return (
        <div className={`${base}__customToast`}>
            <svg
                viewBox="0 0 30 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="check_svg"
            >
                <path
                    d="M0.776855 9.63637L11.8183 20.6778L14.1325 18.3636L3.09102 7.3222L0.776855 9.63637Z"
                    fill="#18D665"
                />

                <path
                    d="M11.8181 20.6778L29.405 3.09092L27.0908 0.776749L9.50391 18.3636L11.8181 20.6778Z"
                    fill="#18D665"
                />
            </svg>

            <p className="message">Sticker reprint request sent</p>
        </div>
    );
}

import { action, observable } from 'mobx';
import * as api from '../services/api';
import moment from 'moment';


class AllOrdersStore {
    @observable loading = true;
    @observable orders = [];

    @action handleGetAllOrders = () => {
        api.getAllOrders(moment(new Date()).format('DD-MM-YYYY'))
            .then(res => {
                if (res) {
                    this.orders = res.sort((a,b) => b.order_id - a.order_id);
                    this.orders = this.orders.filter((order) => (order.production_started_at))
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.loading = false);
    };
}

const store = new AllOrdersStore();
export default store;

import { action, observable } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';

class RiderAndOutletPartnerStore {
    @observable staffMembers = [];

    @action getActiveStaffMembers = () => {
        this.staffMembers = [];
        api.getActiveStaffMembers()
            .then((res) => {
                if (res) {
                    this.staffMembers = res;
                }
            })
            .catch((err) => {
                UserStore.message = err.message;
            });
    }
}

const store = new RiderAndOutletPartnerStore();
export default store;
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import WarmerSKU from './WarmerSKU';

const base = 'orders-kitchen';

@inject('ProductionStore')
@observer
class CurrentWarmerStockCard extends Component {

    render() {
        const { currentWarmerStocks } = this.props;

        return (
            <div
                className={`${base}__order_card_warmer current-stock`}
            >
                <div
                    className={`${base}__card_header order_header_type_warmer current-stock`}
                >
                    <div className={`${base}__icon_num_name`}>
                        <div
                            className={`${base}__order_number_customer_name`}
                        >
                            Items Ready
                        </div>
                    </div>
                </div>
                <div
                    className={`${base}__skus_container sku_container_warmer`}
                >
                    {currentWarmerStocks &&
                    currentWarmerStocks.length > 0 &&
                    currentWarmerStocks.map((item) => (
                        <WarmerSKU
                            item={item}
                            key={`${item.sku}`}
                            isCurrentStock={true}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default CurrentWarmerStockCard;

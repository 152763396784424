import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Loading from '../Loading';
import { Fragment } from 'react';
import Modal from '../Modal';
import { Link } from 'react-router-dom';
import SalePlatformIcon from './SalePlatformIcon';
import AggregatorIcon from './AggregatorIcon'

const base = 'all-orders';

@inject('AllOrdersStore')
@observer
class AllOrders extends Component {
    initialState = {
        showSingleOrderModal: false,
        singleOrder: {},
        from: ""
    };

    state = this.initialState;

    componentDidMount() {
        const {
            location,
            AllOrdersStore: { handleGetAllOrders },
        } = this.props;
        this.setState({from : location ? location.state ? location.state.from : "" : ""});
        handleGetAllOrders();
    }

    handleShowSingleOrderModal = (order) => {
        this.setState({showSingleOrderModal : true, singleOrder: order});
    }
    closeModal = () => {
        this.setState({showSingleOrderModal: false})
    }

    render() {
        const { AllOrdersStore } = this.props;
        const { loading, orders } = AllOrdersStore;
        const {
            showSingleOrderModal,
            singleOrder,
            from
        } = this.state;

        if (loading) {
            return <Loading />;
        }

        if (!orders) {
            return <h1> No orders to show</h1>
        }

        const allOrderNetTotal = (orders.filter((order) => ((order.payment_method == "Cash" && order.status == "Delivered") || (order.transaction_status == "Complete" && order.status != "Cancelled"))).reduce((acc, order) => acc + order.net_total, 0)).toFixed(2);

        return (
            <Fragment>
                <div className='container' style={{margin: '0px', width: '100%', paddingTop: '5px', textAlign : 'center'}}>
                <Link to={from}>
                    <button className={`${base}__view-all-orders  m0 mlm`} style={{position: 'absolute', left: '0px'}}>
                        Go Back
                    </button>
                </Link>
                    <h1 style={{lineHeight: '55px'}}>All Orders</h1>
                </div>
                <div className='container' style={{margin: '0px', width: '100%', padding: '0px'}}>
                    <div className="col-xs-12" 
                        style={{top: '140px', position: 'absolute', backgroundColor: '#303234', color: 'white', 
                        zIndex: '10', textAlign: 'left', paddingTop: '10px', left: '0px'}}>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">ORDER NUMBER</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">ORDER TIME</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">BAG/TABLE NUMBER</h3>
                            </div>
                            <div className="col-xs-2">
                                <h3 className="text--bolder">CUSTOMER NAME</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">CUSTOMER PHONE NUMBER</h3>
                            </div>
                            <div className="col-xs-2">
                                <h3 className="text--bolder">ITEM</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">STATUS</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">PAYMENT METHOD</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">PAYMENT DONE?</h3>
                            </div>
                            <div className="col-xs-1">
                                <h3 className="text--bolder">NET TOTAL <br /> ({allOrderNetTotal}) </h3>
                            </div>
                        </div>              
                        {orders && orders.map((order, key) => (
                            <div className={`${base}__table_row row col-xs-12 text--left`} onClick={() => this.handleShowSingleOrderModal(order)}
                                style = {key=== 0 ? {marginTop: '90px'} : {}} >
                                <div className='col-xs-1' style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                    <div className='col-xs-1' style={{marginRight: '5px', paddingLeft: '0px'}}>
                                        <SalePlatformIcon
                                            key={order.order_number}
                                            order={order}
                                        />
                                    </div>
                                    <div className='col-xs-8' style={(order.order_mode == "DELIVERY" && (order.status == "Pending" || order.status == "Unconfirmed"))
                                        || (order.order_mode == "DINE_IN" && order.status != "Delivered")
                                        || (order.order_mode == "PICK_UP" && (!order.bag_scanned_at && order.status != "Delivered" && order.status != "Intransit")
                                        || (order.status == "Cancelled")) ? {color: 'red'} : {}}>
                                        {order.order_id}
                                    </div>
                                </div>
                                <div className='col-xs-1' style={(order.order_mode == "DELIVERY" && (order.status == "Pending" || order.status == "Unconfirmed"))
                                || (order.order_mode == "DINE_IN" && order.status != "Delivered")
                                || (order.order_mode == "PICK_UP" && (!order.bag_scanned_at && order.status != "Delivered" && order.status != "Intransit")
                                || (order.status == "Cancelled")) ? {color: 'red'} : {}}>
                                    {new Date(order.created_date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                </div>
                                <div className='col-xs-1'>
                                    {order.aggregator_order_id ? order.aggregator_order_id : 
                                        (order.table_number ? "TABLE " + order.table_number : (order.bag_number ? "BAG " + order.bag_number : '-'))}
                                </div>
                                <div className='col-xs-2 text--uppercase'>
                                    {order.customer.alias || order.alias}
                                </div>
                                <div className='col-xs-1'>
                                    {order.customer.mobileno}
                                </div>
                                <div className='col-xs-2'>
                                    {order.menu_item ? order.menu_item.map((item, index) => (
                                        <span>
                                            {item.quantity > 1 ? item.quantity + " x " : "" } {item.name}{index != (order.menu_item.length - 1) ? ", " : ""} 
                                        </span>
                                    )) : ""}
                                </div>
                                <div className='col-xs-1' style={(order.order_mode == "DELIVERY" && (order.status == "Pending" || order.status == "Unconfirmed"))
                                    || (order.order_mode == "DINE_IN" && order.status != "Delivered")
                                    || (order.order_mode == "PICK_UP" && (!order.bag_scanned_at && order.status != "Delivered" && order.status != "Intransit")
                                    || (order.status == "Cancelled"))? {color: 'red'} : {color: '#03C875'}}>
                                    {order.status ? order.status.toUpperCase() : ""}
                                </div>
                                <div className='col-xs-1'>
                                    {order.payment_method ? order.payment_method : "-"}
                                </div>
                                <div className='col-xs-1'>
                                <img src={(order.transaction_status === "Complete" || (order.payment_method === "Cash" && order.status === "Delivered")) ? 
                                                'https://image.popcontent.link/green_checkmark.svg' :
                                                            'https://image.popcontent.link/red_cross.svg'} />
                                </div>
                                <div className='col-xs-1 '>
                                    {order.net_total ? order.net_total.toFixed(2) : "" }
                                </div>
                            </div>
                        ))}

                    {showSingleOrderModal && (
                        <Fragment>
                            <button type="button" className={`${base}__modal_close_button`} aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <Modal height={'80vh'} onBackdropClicked={this.closeModal}>
                                <div className={`${base}__modal_table_title`}>
                                    <SalePlatformIcon
                                        key={singleOrder.order_number}
                                        order={singleOrder}
                                        isModal={true}
                                    />
                                    <div style={{marginLeft: "24px"}}>
                                        Order Number: {singleOrder.order_id}
                                    </div>
                                </div>
                                <hr />
                                <div className={`row ${base}__modal_table_body`}>
                                    <div className={`row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Order Time
                                        </div>
                                        <div className='col-xs-8' style={(singleOrder.order_mode == "DELIVERY" && (singleOrder.status == "Pending" || singleOrder.status == "Unconfirmed"))
                                        || (singleOrder.order_mode == "DINE_IN" && singleOrder.status != "Delivered")
                                        || (singleOrder.order_mode == "PICK_UP" && (!singleOrder.bag_scanned_at && singleOrder.status != "Delivered" && singleOrder.status != "Intransit"))
                                        || (singleOrder.status == "Cancelled") ? {color: 'red'} : {}}>
                                            {new Date(singleOrder.created_date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Bag/Table Number
                                        </div>
                                        <div className='col-xs-8'>
                                        {singleOrder.aggregator_order_id ? singleOrder.aggregator_order_id : 
                                            (singleOrder.table_number ? ("TABLE " + singleOrder.table_number) : ("BAG " + singleOrder.bag_number ? singleOrder.bag_number : "-"))}
                                        </div>
                                    </div>
                                    {
                                        (singleOrder.sale_platform && singleOrder.sale_platform.includes("-")) && (
                                            <div className={`row ${base}__modal_table_row`}>
                                                <div className='col-xs-4 text--gray'>
                                                    Aggregator
                                                </div>
                                                <div className='col-xs-8'>
                                                    <AggregatorIcon 
                                                        key={singleOrder.order_number}
                                                        order={singleOrder}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Customer Name
                                        </div>
                                        <div className='col-xs-8 text--uppercase'>
                                        {singleOrder.customer.alias || singleOrder.alias}
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`} style={{marginBottom: "32px"}}>
                                        <div className='col-xs-4 text--gray'>
                                            Customer Phone Number
                                        </div>
                                        <div className='col-xs-8'>
                                            {singleOrder.customer.mobileno}
                                        </div>
                                    </div>
                                    {singleOrder.menu_item ? singleOrder.menu_item.map((item, index) => (
                                        <div className={`row`}>
                                            <div className='col-xs-4 text--gray'>
                                                {index == 0 ? 'Items' : ''}
                                            </div>
                                            <div className='col-xs-8'>
                                                {item.quantity} x {item.name}
                                            </div>
                                        </div>
                                    )) : ""}
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Status
                                        </div>
                                        <div className='col-xs-8' style={(singleOrder.order_mode == "DELIVERY" && (singleOrder.status == "Pending" || singleOrder.status == "Unconfirmed"))
                                        || (singleOrder.order_mode == "DINE_IN" && singleOrder.status != "Delivered")
                                        || (singleOrder.order_mode == "PICK_UP" && (!singleOrder.bag_scanned_at && singleOrder.status != "Delivered" && singleOrder.status != "Intransit"))
                                        || (singleOrder.status == "Cancelled") ? {color: 'red'} : {color: '#03C875'}}>
                                            {singleOrder.status ? singleOrder.status.toUpperCase() : ""}
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Payment Method
                                        </div>
                                        <div className='col-xs-8'>
                                            {singleOrder.payment_method}
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Payment Done?
                                        </div>
                                        <div className='col-xs-8'>
                                        <img src={(singleOrder.transaction_status === "Complete" || (singleOrder.payment_method === "Cash" && singleOrder.status === "Delivered")) ? 
                                            'https://image.popcontent.link/green_checkmark.svg' :
                                                        'https://image.popcontent.link/red_cross.svg'} />
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Net Total
                                        </div>
                                        <div className='col-xs-8'>
                                            {singleOrder.net_total ? singleOrder.net_total.toFixed(2) : "" }
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Rider Name
                                        </div>
                                        <div className='col-xs-8'>
                                            {singleOrder.third_party_delivery_details.rider_name ? singleOrder.third_party_delivery_details.rider_name : "" }
                                        </div>
                                    </div>
                                    <div className={`row ${base}__modal_table_row`}>
                                        <div className='col-xs-4 text--gray'>
                                            Rider Phone Number
                                        </div>
                                        <div className='col-xs-8'>
                                            {singleOrder.third_party_delivery_details.rider_phone_no ? singleOrder.third_party_delivery_details.rider_phone_no : "" }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default AllOrders;

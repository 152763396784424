import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import Loading from '../Loading';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryReceiveStockBatchInput from './InventoryReceiveStockBatchInput';

const base = 'sku-entry';

@inject('StockTransferStoreAddons', 'UserStore')
@observer
class InventoryReceiveStockUpdateAddons extends Component {
    componentDidMount = () => {
        const {
            StockTransferStoreAddons: { handleGetHubStockTransfersAddons },
        } = this.props;

        handleGetHubStockTransfersAddons();
    };

    handleSetSku = (sku = null) => {
        const {
            StockTransferStoreAddons: { handleSetCurrentSku },
        } = this.props;
        handleSetCurrentSku(sku);
    };

    handleSelectBatch = (selectedBatch = null) => {
        const {
            StockTransferStoreAddons: { handleSelectBatch },
        } = this.props;
        handleSelectBatch(selectedBatch);
    };

    renderHeader = () => {
        const {
            StockTransferStoreAddons: { currentSku, selectedBatch },
        } = this.props;

        const onClickBackLink = selectedBatch
            ? this.handleSelectBatch
            : currentSku
            ? this.handleSetSku
            : null;

        const title = selectedBatch
            ? 'Enter Actual Received'
            : currentSku
            ? 'Select Batch'
            : 'Choose Addons';

        return (
            <InventoryHeader title={title} onClickBackLink={onClickBackLink} />
        );
    };

    renderSkuList = () => {
        const {
            StockTransferStoreAddons: {
                currentSku,
                skusByCategory,
                isSkuReceived,
            },
        } = this.props;

        return (
            <Fragment>
                {this.renderHeader()}
                <div className={`pl`}>
                    <div className={`production__drawer-buttons`}>
                        {Object.keys(skusByCategory).map((category) => (
                            <Fragment key={category}>
                                <h3 className="width--100 text--left fs--medium-large text--bolder">
                                    {category}
                                </h3>
                                {skusByCategory[category].sort().map((sku) => (
                                    <div
                                        key={sku}
                                        className={`production__drawer-button font-small ${
                                            isSkuReceived(sku)
                                                ? 'selected'
                                                : 'unchecked'
                                        } ${
                                            currentSku === sku ? 'current' : ''
                                        }`}
                                        onClick={() => this.handleSetSku(sku)}
                                    >
                                        {sku}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            StockTransferStoreAddons: {
                loading,
                currentSku,
                hubStockTransfers,
                selectedBatch,
                isBatchValid,
            },
        } = this.props;

        if (loading) {
            return <Loading />;
        }

        if (!currentSku) {
            return this.renderSkuList();
        }

        const { inventory_batches, name } = hubStockTransfers[currentSku];

        const startDate = (batch) =>
            moment(batch.arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = (batch) =>
            moment(batch.expires_at, 'x').format('DD/MM/YYYY');
        const isBatchReceived = (batch) =>
            batch.stock_update_tracker && batch.stock_update_tracker.length;

        return (
            <Fragment>
                {this.renderHeader()}
                <div className={`pl`}>
                    <p className={`fs--large text--bolder text--wrap mrs`}>
                        {currentSku}
                    </p>
                    <p className={`fs--medium`}>{name}</p>
                </div>
                {selectedBatch ? (
                    <InventoryReceiveStockBatchInput batch={selectedBatch} />
                ) : (
                    inventory_batches
                        .filter(isBatchValid)
                        .sort((a, b) => a.expires_at - b.expires_at)
                        .map((batch) => (
                            <Fragment key={batch.id}>
                                <div
                                    className={`fs--medium-large ps bg--black text--white`}
                                >
                                    {`${startDate(batch)} - ${endDate(batch)}`}
                                </div>
                                <div
                                    className={`pl mbl display--flex flex-align-items-center justify--space-between`}
                                >
                                    <p className="fs--medium-large">{`${batch.quantity} units`}</p>
                                    <button
                                        className="btn btn-primary width--150px"
                                        disabled={isBatchReceived(batch)}
                                        onClick={() =>
                                            this.handleSelectBatch(batch)
                                        }
                                    >
                                        {isBatchReceived(batch)
                                            ? 'Received'
                                            : 'Receive this batch'}
                                    </button>
                                </div>
                            </Fragment>
                        ))
                )}
            </Fragment>
        );
    }
}

export default InventoryReceiveStockUpdateAddons;

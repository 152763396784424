import { action, observable } from 'mobx';
import * as api from '../services/api';
import * as moment from 'moment';

class WarmerStore {
    @observable warmerItems = [];
    @action
    getWarmerLevel = (hubCode) => {
        let dateTimeToPlan = moment().format('YYYY-MM-DD HH:mm:ss');
        api.getWarmerStockLevel(hubCode, dateTimeToPlan)
            .then((res) => {
                if (res.items) {
                    res.items.forEach((item) => {
                        if (!this.warmerItems) {
                            return;
                        }
                        let existingItem = this.warmerItems.filter(
                            (i) => i.sku === item.sku
                        )[0];
                        if (
                            existingItem &&
                            item.reorder_quantity >
                                existingItem.reorder_quantity
                        ) {
                            const audio = new Audio(
                                `${process.env.PUBLIC_URL}/bell_reduced_volume.mp3`
                            );
                            audio.play();
                        }
                    });
                }
                this.warmerItems = res.items;
            })
            .catch((error) => console.log(error));
    };
}

const store = new WarmerStore();
export default store;

export default {
    basicAuth: 'UkVGSVRVRkw6UVU0NlJrOVBSQQ==',

    // Default city
    defaultCity: 'Kuala_Lumpur',

    //brand & city for http headers
    brand: 'dahmakan',
    city: 'Kuala_Lumpur',

    currencySymbolByCity: {
        Kuala_Lumpur: 'RM',
        Bangkok: '฿',
    },
};

import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Modal from '../Modal';

@inject('InventoryStore')
@observer
class InventoryBatchHistoryModal extends Component {
    state = { showBatchHistoryModal: false };

    handleShowBatchHistoryModal = (selectedBatch) => {
        this.setState({ showBatchHistoryModal: true, selectedBatch });
    };

    handleCloseBatchHistoryModal = () => {
        this.setState({ showBatchHistoryModal: false});
    };

    render() {
        const {
            batch
        } = this.props;
        const { showBatchHistoryModal } = this.state;

        return (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={() =>
                        this.handleShowBatchHistoryModal(this.props.batch)
                    }
                >
                    Batch history
                </button>
                {showBatchHistoryModal && (
                    <Modal>
                        <div className="mbm text--center">
                            <div className="history-modal">
                                <span>Update History</span>
                                <table className="history-table">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>New Quantity</th>
                                        <th>Delta</th>
                                        <th>New Quantity Sold</th>
                                        <th>Emergency Boxes</th>
                                        <th>QA Boxes</th>
                                        <th>User</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.batch.stock_update_tracker &&
                                    this.props.batch.stock_update_tracker.map((stock)=>
                                        <tr key ={stock.updated_at}>
                                            <td>{moment(stock.updated_at, 'x').format('DD/MM/YYYY HH:mm A')}</td>
                                            <td>{stock.quantity}</td>
                                            <td>{stock.delta}</td>
                                            <td>{stock.quantity_sold}</td>
                                            <td>{stock.emergency_boxes_quantity}</td>
                                            <td>{stock.quality_assurance_boxes_quantity}</td>
                                            <td>{stock.initiator_username}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div className="history-modal">
                                <span>Check History</span>
                                <table className="history-table">
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Quantity</th>
                                        <th>Delta</th>
                                        <th>Reason</th>
                                        <th>User</th>
                                        <th>Production</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.batch.stock_check_tracker &&
                                    this.props.batch.stock_check_tracker.map((stock)=>
                                        <tr key ={stock.updated_at}>
                                            <td>{moment(stock.updated_at, 'x').format('DD/MM/YYYY hh:mm A')}</td>
                                            <td>{stock.quantity}</td>
                                            <td>{stock.delta}</td>
                                            <td>{stock.reason}</td>
                                            <td>{stock.initiator_username}</td>
                                            <td>{stock.production && "True"}
                                                {!stock.production && "False"}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <button
                                className="btn flex-1"
                                onClick={this.handleCloseBatchHistoryModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryBatchHistoryModal;

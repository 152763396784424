import React, { Component, Fragment } from 'react';
import InventoryHeader from './InventoryHeader';
import { inject, observer } from 'mobx-react';
import Select from 'react-select';
import ComponentStockStore from '../../stores/ComponentStockStore';
import Modal from '../Modal';
import UserStore from "../../stores/common/UserStore";

const base = 'inventory-component-add';

@inject('ComponentStockStore')
@observer
class InventoryAddComponentStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityPerPackaging: [],
            showConfirmationModal: false
        };
        this.setQuantity = this.setQuantity.bind(this)
    }

    componentDidMount() {
        ComponentStockStore.handleGetUnitSkus();
        this.setState({ quantityPerPackaging: ComponentStockStore.quantityPerPackaging });
    }

    handleSetSelectedUnitSku = (unitSku) => {
        ComponentStockStore.setSelectedUnitSku(unitSku);
        this.setState({ quantityPerPackaging: ComponentStockStore.quantityPerPackaging });
    }

    setQuantity = (id, event) => {
        let quantityPerPackaging = [...this.state.quantityPerPackaging];
        quantityPerPackaging.filter(p => p.id === id)[0].quantity = parseInt(event.target.value);
        this.setState({quantityPerPackaging});
        ComponentStockStore.quantityPerPackaging = quantityPerPackaging;
    }

    openConfirmationModal = (event) => {
        event.preventDefault();
        const {
            ComponentStockStore: {
                selectedUnitSku,
            },
        } = this.props;

        if (this.getTotalQuantity(this.state.quantityPerPackaging) > 10000) {
            alert('The quantities added here do not seem right. Please alert the tech team if this is not the case.');
        } else if (selectedUnitSku) {
            this.setState({ showConfirmationModal: true });
        }
    }

    cleanUpAfterSubmit() {
        const {
            ComponentStockStore: {
                cleanUpForm
            }
        } = this.props;
        this.setState({
            showConfirmationModal: false,
            quantityPerPackaging: []
        });
        cleanUpForm();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        ComponentStockStore.handleSaveComponentStock(this.state.quantityPerPackaging).then((res) => {
            this.cleanUpAfterSubmit();
        }).catch((error) => {
            this.cleanUpAfterSubmit();
            UserStore.message = error.message});
    }

    getTotalQuantity = (packagingList) => {
        return packagingList.reduce((totalQuantity, packaging) => (packaging.quantity ? totalQuantity + (packaging.innerQuantity * packaging.quantity) : totalQuantity), 0);
    }

    render() {
        const {
            ComponentStockStore: {
                unitSkus,
                selectedUnitSku,
                selectedStatus,
                setSelectedStatus,
                supplier,
                setSupplier,
                batchId,
                setBatchId
            },
        } = this.props;
        const {
            quantityPerPackaging,
            showConfirmationModal
        } = this.state;
        let unitSkuOptions;
        if (unitSkus){
            unitSkuOptions = unitSkus.filter((unitSku) => !unitSku.subComponent)
        }
        const statuses = [{ value: 'STOCKED', label: 'STOCKED' }, { value: 'EXPIRED', label: 'EXPIRED' }];
        return (
            <Fragment>
                <InventoryHeader title='Add Component Stock' />
                <form className='pm' style={{ maxWidth: '1200px' }} onSubmit={this.openConfirmationModal}>
                    <div
                        className={`grid grid-1-1`}
                    >
                        <label className='fs--medium-medium-large'>SKU</label>
                        <Select
                            className='fs--medium-medium-large'
                            value={selectedUnitSku}
                            autoFocus
                            required
                            options={unitSkuOptions}
                            style={{ width: '100%', marginBottom: '10px' }}
                            name='unitSkus'
                            onChange={this.handleSetSelectedUnitSku}
                            placeholder='Select SKU'
                        />
                        <label className='fs--medium-medium-large'>Packaging</label>
                        <label className='fs--medium-medium-large'>Quantity</label>
                        {quantityPerPackaging.map(p => (
                                <Fragment key={p.id}>
                                    <input
                                        className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--300px center--horizontal ps'
                                        value={p.packaging}
                                        readOnly
                                    />
                                    <input
                                        className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--200px center--horizontal ps'
                                        type='number'
                                        name='inputNumber'
                                        min={0}
                                        value={p.quantity}
                                        onChange={this.setQuantity.bind(this, p.id)}
                                    />
                                </Fragment>
                            ),
                        )}
                        <label className='fs--medium-medium-large mtm'>Supplier</label>
                        <input
                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--400px center--horizontal ps mtm'
                            type='text'
                            value={supplier}
                            onChange={setSupplier}
                        />
                        <label className='fs--medium-medium-large'>Batch Id</label>
                        <input
                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--400px center--horizontal ps'
                            type='text'
                            value={batchId}
                            onChange={setBatchId}
                        />
                        <label className='fs--medium-medium-large'>Status</label>
                        <Select
                            className='fs--medium-medium-large width--200px center--horizontal'
                            value={selectedStatus}
                            required
                            options={statuses}
                            name='statuses'
                            onChange={setSelectedStatus}
                            placeholder='Select Status'
                        />
                    </div>
                    <input
                        className="btn btn-primary width--300px mtxl"
                        type="submit"
                        value="Add"
                    />
                </form>

                {showConfirmationModal && (
                    <Modal width={'700px'}>
                        <div>
                            <p style={{fontSize: '24px'}}>
                                Adding for {selectedUnitSku.label}:
                            </p>
                            <ul style={{fontSize: '20px'}}>
                            {quantityPerPackaging.map(p => (
                                    <li key={p.id}>
                                        <span>{p.quantity ? p.quantity : 0} x {p.packaging}</span>
                                    </li>
                                )
                            )}
                                <br/>
                                <li>Total quantity to be added : {this.getTotalQuantity(quantityPerPackaging) + " " + quantityPerPackaging[0].packaging}</li>
                            </ul>
                            <div className={`${base}__confirmation_btns`}>
                                <button
                                    className="btn btn-secondary ms flex-1"
                                    onClick={() => {
                                        this.setState({
                                            showConfirmationModal: false,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary ms flex-1"
                                    onClick={this.handleSubmit}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default InventoryAddComponentStock;

import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';
import SalePlatformIcon from './SalePlatformIcon';

const base = 'dispatch_scan';

class BagScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        const customerName = scannedItem.customer_name;

        const customerNameToShow = customerName ? 
                                    (customerName.length > 13
                                        ? customerName.slice(0, 10) + '...'
                                        : customerName) : '';
        const referenceNumber = scannedItem.table_number ? scannedItem.table_number : scannedItem.bag_number;
        let orderNumberToShow = referenceNumber;
        let userInfoToShow = customerNameToShow ? customerNameToShow : (orderNumberToShow ? orderNumberToShow : "");
        let scannedInfoToShow = scannedItem.number_of_scanned_items + "/" + scannedItem.number_of_items;
        let salePlatform = null;
        if (scannedItem.item) {
            salePlatform = scannedItem.item.sale_platform;
        } else {
            salePlatform = scannedItem.sale_platform
        }

        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        return (
             <div className={`${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <SalePlatformIcon 
                        scannedItem = {scannedItem}/>
                    {(salePlatform == 'PopMeals') && (
                        <span className="delivery_icon_text">{referenceNumber}</span>
                    )}
                    </div>
                    <div className="order_info">
                        <p className={`${base}__user_info`}>
                            {userInfoToShow}
                        </p>
                        <p className={`${base}__scanned_info`}>
                            {scannedInfoToShow} items scanned
                        </p>
                    </div>
                </div>
                    <MealImagesSection
                        originalItemImageLink={originalItemImageLink}
                        itemWebCamImage={itemWebCamImage}
                    />
            </div>
            );
        }
    }
    
export default BagScannerView;
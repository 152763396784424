import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import CycleSKU from './CycleSKU';
import SalePlatformIcon from "./SalePlatformIcon";

const base = 'orders-kitchen';
const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
const FOUR_SECONDS_IN_MILLISECONDS = 5 * 1000;

@inject('ProductionStore')
@observer
class OrderCard extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        isNewOrder: false,
        appearTimestamp: null
    };

    componentDidMount = () => {
        const { order, ProductionStore } = this.props;
        const now = moment(order.order_date, 'x');
        const then = moment();

        if (ProductionStore.lastOrderTimeStamp == null || order.order_date > ProductionStore.lastOrderTimeStamp) {
            ProductionStore.lastOrderTimeStamp = order.order_date;
            this.setState({ isNewOrder: true });
            ProductionStore.playAudio();
        }

        if (!this.state.appearTimestamp) {
            this.setState({ appearTimeStamp: then });
        }

        if (moment.duration(then.diff(this.state.appearTimestamp)).asMilliseconds() > FOUR_SECONDS_IN_MILLISECONDS) {
            this.setState({ isNewOrder: false });
        }

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true, isNewOrder: false });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { order } = this.props;
        if (order.order_date !== prevProps.order.order_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };




    countdown = () => {
        const { order, ProductionStore } = this.props;
        const now = moment((order.pick_up_type == "DINE_IN" || order.pick_up_type == "TAKE_AWAY") ? 
            (order.production_started_at ? order.production_started_at : order.order_date) : order.order_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (duration < 0) {
            // future order.
            this.setState({
                hours,
                minutes,
                seconds,
                isNewOrder: false,
            });
        } else {
            if (this.state.appearTimestamp == null) {
                ProductionStore.playAudio();
                this.setState({ hours, minutes, seconds, isNewOrder: true, appearTimestamp: then });
            } else {
                if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
                    this.setState({ hours, minutes, seconds, delay: true, isNewOrder: false });
                } else {
                    if (moment.duration(then.diff(this.state.appearTimestamp)).asMilliseconds() > FOUR_SECONDS_IN_MILLISECONDS) {
                        this.setState({
                            hours,
                            minutes,
                            seconds,
                            isNewOrder: false,
                        });
                    } else {
                        this.setState({
                            hours,
                            minutes,
                            seconds
                        });
                    }

                }
            }
        }
    };

    render() {
        const { order } = this.props;
        const meals = order.items.filter((item) => item.item_type === 'Item' && item.sku != 'Cutlery');
        const cutlery = order.items.filter((item) => item.item_type === 'Item' && item.sku == 'Cutlery');
        const addons = order.items.filter((item) => item.item_type === 'Addons' && item.category_name != 'Drinks');
        const drinks = order.items.filter((item) => item.item_type === 'Addons' && item.category_name == 'Drinks');
        const {
            hours,
            minutes,
            seconds,
            delay: isOrderDelay,
            isNewOrder,
        } = this.state;

        const customerName = order.customer_name || '';
        const referenceNumber = order.table_number ? order.table_number : order.pick_up_number;
        let orderNumberToShow = order.pick_up_type
            ? referenceNumber
            : order.bag_number;
        let orderInfoToShow = (orderNumberToShow ? orderNumberToShow : "") + " " + customerName;
        orderInfoToShow = orderInfoToShow.length > 16 ? orderInfoToShow.slice(0,13) + "..." : orderInfoToShow;

        let brandName = null
        if (order.sale_platform) {
            if(order.sale_platform.includes('GrabFood')) {
                if (order.sale_platform.indexOf(' - GrabFood') > -1){
                    brandName = order.sale_platform.split(' - GrabFood')[0];
                }
                orderNumberToShow = null;
            } else if(order.sale_platform.includes('FoodPanda')) {
                if (order.sale_platform.indexOf(' - FoodPanda') > -1){
                    brandName = order.sale_platform.split(' - FoodPanda')[0];
                }
                orderNumberToShow = null;
            } else if (order.sale_platform.includes('ShopeeFood')){
                if (order.sale_platform.indexOf(' - ShopeeFood') > -1){
                    brandName = order.sale_platform.split(' - ShopeeFood')[0];
                }
                orderNumberToShow = null;
            }
        }
        const orderType = !order.pick_up_type
            ? 'DELIVERY'
            : order.pick_up_type === 'TAKE_AWAY'
            ? 'TAKE_AWAY'
            : 'DINE_IN';

        return (
            <div
                className={`${base}__order_card ${base}__order_card_${
                    (orderType === 'DELIVERY' || !orderNumberToShow)
                        ? 'DELIVERY'.toLowerCase()
                        : orderType === 'TAKE_AWAY'
                        ? 'TAKE_AWAY'.toLowerCase()
                        : 'DINE_IN'.toLowerCase()
                }  ${isOrderDelay && 'delay'}`}
            >
                <div
                    className={`${base}__card_header  ${
                        isNewOrder &&
                        `new-order-${
                            (orderType === 'DELIVERY' || !orderNumberToShow)
                                ? 'black-white'
                                : 'black-yellow'
                        }`
                    }  order_header_type_${
                        (orderType === 'DELIVERY' || !orderNumberToShow)
                            ? 'DELIVERY'.toLowerCase()
                            : orderType === 'TAKE_AWAY'
                            ? 'TAKE_AWAY'.toLowerCase()
                            : 'DINE_IN'.toLowerCase()
                    } ${isOrderDelay && 'delay'}`}
                >
                    <div className={`${base}__icon_num_name`}>
                        <SalePlatformIcon
                            key={order.order_number}
                            order={order}
                            isOrderDelay = {isOrderDelay}
                            isNewOrder = {isNewOrder}
                        />

                        <div
                            className={`${base}__order_number_customer_name ${
                                isNewOrder &&
                                `new-order-${
                                    (orderType === 'DELIVERY' || !orderNumberToShow)
                                        ? 'black-white'
                                        : 'black-yellow'
                                }`
                            }  ${isOrderDelay && 'delay'}`}
                        >
                            {orderInfoToShow}
                        </div>
                    </div>

                    <p
                        className={`${base}__time_elapse ${
                            isNewOrder &&
                            `new-order-${
                                (orderType === 'DELIVERY' || !orderNumberToShow)
                                    ? 'black-white'
                                    : 'black-yellow'
                            }`
                        }  ${isOrderDelay && 'delay'}`}
                    >
                        {`${
                            parseInt(hours) > 0 ? `${hours}:` : ``
                        }${minutes}:${seconds}`}
                    </p>
                </div>
                <div
                    className={`${base}__skus_container sku_container_${
                        (orderType === 'DELIVERY' || !orderNumberToShow)
                            ? 'DELIVERY'.toLowerCase()
                            : orderType === 'TAKE_AWAY'
                            ? 'TAKE_AWAY'.toLowerCase()
                            : 'DINE_IN'.toLowerCase()
                    } sku_container_${isOrderDelay && 'delay'}`}
                >
                    {brandName &&
                        <div className={`${base}__sku_item`} style={{justifyContent: 'center', background: '#4154FF', color: 'white', height: '3rem'}}>
                            <p className="aggregator_name">
                                {brandName.toUpperCase()}
                            </p>
                        </div>
                    }

                    {meals &&
                        meals.length > 0 &&
                        meals.map((item, index) => (
                            <CycleSKU
                                item={item}
                                type={'meals'}
                                isFirstItem={index == 0}
                                isLastItem={index == meals.length - 1}
                                isLastCategory={addons.length == 0 && drinks.length == 0 && cutlery.length == 0}
                                key={`${item.sku}_${item.incremental_number}`}
                            />
                        ))}
                    {addons &&
                        addons.length > 0 &&
                        addons.map((item, index) => (
                            <CycleSKU
                                item={item}
                                type={'addons'}
                                isFirstItem={index == 0}
                                isLastItem={index == addons.length - 1}
                                isLastCategory={drinks.length == 0 && cutlery.length == 0}
                                key={`${item.sku}_${item.incremental_number}`}
                            />
                        ))}
                    {drinks &&
                        drinks.length > 0 &&
                        drinks.map((item, index) => (
                            <CycleSKU
                                item={item}
                                type={'drinks'}
                                isFirstItem={index == 0}
                                isLastItem={index == drinks.length - 1}
                                isLastCategory={cutlery.length == 0}
                                key={`${item.sku}_${item.incremental_number}`}
                            />
                        ))}
                    {cutlery &&
                        cutlery.length > 0 &&
                        cutlery.map((item, index) => (
                            <CycleSKU
                                item={item}
                                type={'cutlery'}
                                isFirstItem={index == 0}
                                isLastItem={index == cutlery.length - 1}
                                isLastCategory={true}
                                key={`${item.sku}_${item.incremental_number}`}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default OrderCard;

import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
const base = 'all-orders';


@observer
class AggregatorIcon extends Component {
    render() {
        const { order } = this.props;
        let salePlatform = order.sale_platform;

        if (!salePlatform || !salePlatform.includes("-")) {
            return;
        }

        return (
            <Fragment>
                {salePlatform.includes('Pasta & Co') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FPastaCo.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Mac & Cheese Bros') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FMacAndCheeseBros.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Royale Mac & Cheese') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FRoyaleMacAndCheese.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Mac & Cheese Boss') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FMacAndCheeseBoss.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Lord of The Mac') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FLordOfTheMac.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Sambal Sambal') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FSambalSambal.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('House of Buttermilk') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FHouseOfButtermilk.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('FryDays') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FFryDays.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Little Italy') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FLittleItaly.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Pasta Rasa') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FPastaRasa.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('La Famiglia Pasta') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FLaFamigliaPasta.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Sambalicious') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FSambalicious.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Warung Indo') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FWarungIndo.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes("Crisp n' Crave") && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FCrispnCrave.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Citra Nusantara') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FCitraNusantara.png"
                        alt={salePlatform}
                    />)}
                {(salePlatform.includes('Restoran Mohd Lim') && 
                    salePlatform.includes('ShopeeFood')) && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FRumahNasiTeratai.png"
                        alt={salePlatform}
                    />)}
                {(salePlatform.includes('Restoran Mohd Lim') && 
                    salePlatform.includes('GrabFood')) && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FLotusRiceHouse.png"
                        alt={salePlatform}
                    />)}
                {(salePlatform.includes('Restoran Mohd Lim') && 
                    !salePlatform.includes('ShopeeFood') && 
                    !salePlatform.includes('GrabFood')) && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FRestoranMohdLim.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Oriental Garden') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FOrientalGarden.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Ayam Kicap Gemilang') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FAyamKicapGemilang.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Ayam Gepuk Gajus') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FAyamGepukGajus.png"
                        alt={salePlatform}
                    />)}
                {salePlatform.includes('Ayam Penyet Berkat') && (
                    <img
                        className={`${base}__modal_aggregator_icon`}
                        src="https://image.popcontent.link/aggregator-logos%2FAyamPenyetBerkat.png"
                        alt={salePlatform}
                    />)}
            </Fragment>
        );
    }
}

export default AggregatorIcon;

import React, { Component, Fragment } from 'react';
import Modal from '../Modal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

const base = 'sku-entry';

@inject('StockTransferStoreAddons', 'UserStore')
@observer
class InventoryReceiveStockBatchInput extends Component {
    initialState = {
        quantityInput: '',
        showConfirmationModel: false,
        errorMessage: '',
    };

    state = this.initialState;

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModel: false });
    };

    handleSetQuantity = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = () => {
        const { quantityInput } = this.state;
        const { batch } = this.props;
        const { quantity } = batch;

        if (Math.abs(quantity - quantityInput) > 0) {
            const errorMessage = {
                quantity,
                quantityInput,
            };
            this.setState({ showConfirmationModel: true, errorMessage });
        } else {
            this.sendRequest();
        }
    };

    sendRequest = () => {
        const { quantityInput } = this.state;
        const {
            StockTransferStoreAddons: { handleUpdateTransferPlanAddons },
            batch,
        } = this.props;

        let request = {
            ...batch,
            quantity: parseInt(quantityInput),
        };

        handleUpdateTransferPlanAddons(request);
        this.setState(this.initialState);
    };

    handleSetSku = () => {
        const {
            StockTransferStoreAddons: {
                handleSetCurrentSku,
                handleSelectBatch,
            },
        } = this.props;
        handleSelectBatch(null);
        handleSetCurrentSku(null);
    };

    render() {
        const {
            showConfirmationModel,
            errorMessage,
            quantityInput,
        } = this.state;
        const { batch } = this.props;

        const startDate = moment(batch.arrival_at, 'x').format('DD/MM/YYYY');
        const endDate = moment(batch.expires_at, 'x').format('DD/MM/YYYY');
        const disabled =
            batch.stock_update_tracker && batch.stock_update_tracker.length;

        return (
            <div className="mbxl pbxl">
                <Fragment>
                    <div className={`${base} grid grid-gap-medium pl pb0`}>
                        <h3 className="text--right m0">Selling Date:</h3>
                        <p className="text--left fs--medium m0">{startDate}</p>
                        <h3 className="text--right m0">Expiry Date:</h3>
                        <p className="text--left fs--medium m0">{endDate}</p>
                        <h3 className="text--right m0">Expected Quantity:</h3>
                        <p className="text--left fs--medium m0">{`${batch.quantity} units`}</p>
                        <label className="text--right fs--medium-medium-large mtxl">
                            Actual Stock Received:
                        </label>
                        <input
                            className="border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--100px ps  mtxl"
                            name="quantityInput"
                            type="number"
                            value={disabled ? batch.quantity : quantityInput}
                            onChange={this.handleSetQuantity}
                            disabled={disabled}
                        />
                    </div>
                </Fragment>
                <div className="footer-bottom">
                    {disabled ? (
                        <button
                            className={`btn width--75 btn-selected`}
                            onClick={this.handleSetSku}
                        >
                            Choose Addons
                        </button>
                    ) : (
                        <button
                            className={`btn width--75 btn-selected`}
                            onClick={this.handleSubmit}
                            disabled={disabled}
                        >
                            Submit
                        </button>
                    )}
                </div>
                {showConfirmationModel && (
                    <Modal>
                        <h3>
                            Attention! Big difference in count, are you sure
                            your count is correct?
                        </h3>
                        <div className="mbm">
                            <div className="modal-content">{`Actual Count = ${errorMessage.quantity} meals`}</div>
                            <div className="modal-content">{`Your Count = ${errorMessage.quantityInput} meals`}</div>
                        </div>
                        <div className="display--flex justify--space-around">
                            <button
                                className="btn ms"
                                onClick={this.handleCloseConfirmationModal}
                            >
                                Count again
                            </button>
                            <button
                                className="btn btn-primary ms"
                                onClick={this.sendRequest}
                            >
                                Yes, save count
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

export default InventoryReceiveStockBatchInput;

import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import InventoryComponentBatchHistoryModal from './InventoryComponentBatchHistoryModal';
import ComponentStockStore from "../../stores/ComponentStockStore";
import InventoryStore from "../../stores/InventoryStore";

const base = 'sku-entry';

@inject('ComponentStockStore')
@observer
class SubComponentStockInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantityPerPackaging: [],
        };
    }

    componentDidMount = () => {
        const {
            batch: {unit_sku},
            quantityPerPackaging
        }= this.props;
        let packaging = '';
        if (quantityPerPackaging) {
            packaging = quantityPerPackaging;
        } else {
            packaging = ComponentStockStore.getPackaging({sku: unit_sku});
        }
        this.setState({quantityPerPackaging: packaging});
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        const { name, value, dataset } = e.target;
        let {
            quantityPerPackaging
        }= this.state;
        quantityPerPackaging.filter(p => p.id === name)[0].quantity = parseInt(value);
        this.setState({quantityPerPackaging});
        onChange(dataset.sku, this.calculateUnitQuantity(), quantityPerPackaging);
    };

    calculateUnitQuantity = () => {
        const {
            quantityPerPackaging
        }= this.state;
        let totalQuantity = 0;
        for (const packaging of quantityPerPackaging) {
            if (!packaging.quantity) {
                continue;
            }
            totalQuantity += packaging.quantity * packaging.innerQuantity;
        }
        return totalQuantity;
    }

    render() {
        const {
            batch: {
                unit_sku,
                name
            },
        } = this.props;
        const {
            quantityPerPackaging
        } = this.state;

        return (
            <Fragment>
                {quantityPerPackaging && quantityPerPackaging.map(p => (
                    <Fragment key={p.id}>
                        <div className={`fs--medium-large ps bg--black text--white`}>
                            {unit_sku + ' : ' + name}
                        </div>
                        <div className={`${base} pl`}  style={{marginBottom: '5%'}}>
                            <div className="col-lg-10">
                                <div className="grid grid-1-1">
                                    <label className='fs--medium-medium-large'>Packaging</label>
                                    <label className='fs--medium-medium-large'>Quantity</label>
                                        <input
                                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--300px center--horizontal ps'
                                            value={p.packaging}
                                            readOnly
                                        />
                                        <input
                                            className='border--rounded border--solid fs--medium-medium-large text--center text--black text--bolder width--200px center--horizontal ps'
                                            type='number'
                                            name={p.id}
                                            data-sku={unit_sku}
                                            min={0}
                                            value={p.quantity === undefined ? '' : p.quantity}
                                            onChange={this.handleChange}
                                        />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ))}
            </Fragment>
        );
    }
}

export default SubComponentStockInput;
